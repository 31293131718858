import React from "react";
import styled from "styled-components";

import Container from "../../components/Container";
import Button from "../../components/Button";
import Link from "../../components/Link";

const StyledInnovemosSection = styled.section`
  background-color: #3345da;
  color: white;
  position: relative;
  z-index: 100;
  h3 {
    font-size: 18px;
    margin-bottom: 3rem;
    margin-top: 2rem;
  }

  .cont {
    margin-bottom: 2rem;
  }

  h2,
  h1 {
    font-size: 25px;
    margin: 0;
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    h3 {
      font-size: 22px;
    }

    h2,
    h1 {
      font-size: 45px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    h2 {
      font-size: 60px;
    }
    h1 {
      font-size: 84px;
    }

    .trabaja {
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1400px) {
    .trabaja {
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export default function InnovemosSection() {
  return (
    <StyledInnovemosSection>
      <Container className="trabaja">
        <div className="cont">
          <h3>¿Te interesan nuestros servicios? ¡Contáctanos!</h3>
          <h2>hola@bahia360.mx</h2>
          <h1>Innovemos juntos.</h1>
        </div>
        <Link to="/contactanos">
          <Button button_text="CONTACTO" whiteFont />
        </Link>
      </Container>
    </StyledInnovemosSection>
  );
}
