var serverAddress =
  process.env.REACT_APP_API_ADDRESS || "https://site.api.bahia360.mx";

export default class SDK {
  static request(endpoint) {
    return fetch(serverAddress + endpoint)
      .then((res) => res.json())
      .then((resultado) => resultado);
  }

  static getServicios() {
    return this.request("/servicios").then((res) => {
      return res;
    });
  }

  static getServicio(id) {
    return this.request("/servicios?slug=" + id).then((res) => {
      return res;
    });
  }

  static getProyectos() {
    return this.request("/proyectos").then((res) => {
      return res;
    });
  }

  static getProyecto(id) {
    return this.request("/proyectos?slug=" + id).then((res) => {
      return res;
    });
  }

  static getTareasByService(service_id) {
    return this.request("/tareas?servicio=" + service_id).then((res) => {
      return res;
    });
  }

  static getClients() {
    return this.request("/clientes").then((res) => {
      return res;
    });
  }
  static getArticulos() {
    return this.request("/articulos").then((res) => {
      return res;
    });
  }

  static getArticulo(id) {
    return this.request("/articulos?slug=" + id).then((res) => {
      return res;
    });
  }

  static getArticuloSlugById(id){
    return this.request("/articulos/" + id).then((res) => {
      return res.slug;
    });
  }
}
