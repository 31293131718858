import React, { useEffect } from 'react'
import Trophy from "./../assets/images/trophy.png";
import styled from "styled-components";

const StyledAnimatedImage = styled.div`
    .canvas{
        // width: 200px;
        // heigth: 100px;
        // background: red;
        position: relative
    }

    // #cursor{
    //     width: 120px;
    //     height: 104px;
    //     transition: 0.1s;
    //     pointer-events: none;
    //     transition: 0.1s;
    //     // position: absolute
    // }
`;
export const AnimatedImage = () => {
    
    // useEffect(()=>{
    //     const cursor =  document.getElementById('cursor');
    //     document.addEventListener('mousemove', e =>{
    //       console.log(e, 'este')
    //       const x = e.clientX;
    //       const y = e.clientY;
    //     //   cursor.style.position='absolute';
    //       cursor.style.left =  x + 'px';
    //       cursor.style.top = y +'px';
    //     }, false)
    //   },[])
  return (
      <StyledAnimatedImage>
          <div 
            // onMouseMove={e=>console.log(e)} 
            className="canvas"
            >
            <img src={Trophy} id="cursor"/>
        </div>
    </StyledAnimatedImage>
  )
}
