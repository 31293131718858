import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: ${(props) => (props.dark ? "#232323" : "white")};
  color: ${(props) => (props.whiteFont ? "white" : "black")};
  cursor: pointer;
  border: none;
  background: transparent;
  z-index: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  position: relative;
  width: max-content;
  padding: 0;
  height: 56px;
  // animation-name: button-hover;
  // animation-duration: 4s;

  &:before {
    position: absolute;
    z-index: -1;
    content: " ";
    top: 0;
    bottom: 0;
    left: -10px;
    margin: auto;
    width: 56px;
    height: 56px;
    display: inline-block;
    border-radius: 28px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    background: ${(props) =>
      props.dark ? "#A04D9A;" : "rgba(248, 248, 251, 0.4)"};
    color: ${(props) => (props.whiteFont ? "white" : "black")};
  }

  :hover {
    &:before {
      animation: button-hover 2.5s infinite ease-in-out;
    }
  }
`;

export default function Button({
  button_text,
  dark,
  whiteFont,
  type,
  onClick,
}) {
  return (
    <StyledButton
      dark={dark ? true : false}
      whiteFont={whiteFont ? true : false}
      type={type}
      onClick={onClick}
    >
      {button_text} &nbsp;+
    </StyledButton>
  );
}
