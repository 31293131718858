import React, { Component } from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import Video from "../../components/Video";

import InnovemosSection from "./InnovemosSection";
import OtrosProyecto from "./OtrosProyecto";
import Footer from "./Footer";
import ReactMarkdown from "react-markdown";

import appstore from "../../assets/images/App_Store.png";
import googlestore from "../../assets/images/Google_Play.png";
import arrowLeft from "../../assets/images/Icon/arrowLeft.svg";

const StyledProyectoImages = styled.div`
  /* *{
    border: 1px solid red;
} */

  position: relative;

  .head {
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0;
      font-family: Poppins-light;
      margin-bottom: 30px;
      align-self: flex-start;
    }
    p {
      margin: 0;
      margin-bottom: 30px;
    }

    a {
      cursor: pointer;
      align-self: center;
      justify-self: center;
      text-decoration: none;
      color: white;
      margin: 10px 0;
      font-weight: bold;
      span {
        color: #a04d9a;
      }

      ::after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        background: white;
        transition: width 0.3s;
      }
      :hover::after {
        width: 100%;
        //transition: width .3s;
      }
    }
  }

  .imagenes {
    margin-left: auto;
    .imagen {
      margin-bottom: 30px;
      display: flex;
      img,
      video {
        object-fit: contain;
        max-width: 100%;
        margin: auto;
      }
    }
  }

  .metrics {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    .metric {
      background: #3345da;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      flex: 1 0 30%;

      height: 70px;

      margin-bottom: 20px;
      padding: 10px;
      align-items: center;
      justify-content: center;

      span {
        font-weight: bold;
        margin-bottom: 10px;
      }
      p {
        text-align: center;
        margin: 0 !important;
      }
    }

    .metric:nth-child(odd) {
      margin-right: 20px;
    }
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    .head {
      position: sticky;
      top: 0;
      height: 100vh;
      margin: 0;
      max-width: 40%;
      padding-right: 60px;
      h1 {
        margin-bottom: 60px;
      }
      p {
        margin-bottom: 60px;
      }
    }

    .imagenes {
      .imagen {
        margin-bottom: 60px;
      }
    }
    .black-container,
    .gray-container {
      display: flex;
    }

    .metric {
      flex: 0 0 30% !important;
      height: 80px !important;
      padding: 15px !important;
    }
  }
  .gray-container {
    .head {
      margin-top: 6rem;
    }
  }

  @media (min-width: 992px) {
    .imagenes {
      margin-top: 6rem;
    }

    .black {
      .head {
        h1 {
          margin-top: 100px;
        }
      }
    }
  }
`;

const StyledBlack = styled.div`
  background: #000000;
  color: white;
  position: sticky;
`;

const StyledGray = styled.div`
  position: sticky;
  background: #e2e2ea;
`;

const StyledBlueQR = styled.div`
  position: sticky;
  /* background-color: #4c00dd; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  color: white;
  h1 {
    margin-top: 0;
    font-family: Poppins-light;
    font-size: 30px;
  }
  .imageQr {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    .store_images {
      max-height: 250px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      a{
        text-decoration: none;
        color: black;
      }
      button {
        cursor: pointer;
        background-color: transparent;
        border: 0px;
        margin: 20px;
      }
      .linkweb{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #11374F;
        font-size: 16px;
        img{
          margin-left: 10px;
          margin-bottom: 2px;
        }
      }
    }
  }
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 450px;
    .imageQr {
      margin-top: 0px;
      flex-direction: row;
    }
    .textQr {
      max-width: 400px;
      p {
        margin-right: 20px;
        text-align: justify;
      }
    }
  }
  @media (min-width: 1400px) {
    .textQr {
      max-width: 400px;
      p {
        margin-right: -30px;
        text-align: justify;
      }
      h1 {
        max-width: 350px;
      }
    }
  }
`;
class PrjectImages extends Component {
  render() {
    const back = this.props.proyecto.background;
    const colorLetter = this.props.proyecto.colorLetter;
    return (
      <StyledProyectoImages>
        <StyledBlack className="black">
          <Container className="black-container">
            <div className="head">
              <h1>{this.props.proyecto.DescriptionTitle}</h1>

              <div className="text">
                <ReactMarkdown>
                  {this.props.proyecto.DescriptionText}
                </ReactMarkdown>
              </div>
              {this.props.proyecto.DescriptionMetrics ? (
                <div className="metrics">
                  {Object.keys(this.props.proyecto.DescriptionMetrics)
                    .sort()
                    .map((k) => {
                      return (
                        <div key={"metric_" + k} className="metric">
                          <span>
                            {this.props.proyecto.DescriptionMetrics[k].number}
                          </span>
                          <p>
                            {this.props.proyecto.DescriptionMetrics[k].title}
                          </p>
                        </div>
                      );
                    })}
                </div>
              ) : null}

              <a
                href="https://www.behance.net/bahiahub"
                target="_blank"
                rel="noopener noreferrer"
              >
                CONOCE NUESTRO BEHANCE{" "}
                <span style={{ color: "#A04D9A" }}>&rarr;</span>{" "}
              </a>
            </div>

            <div className="imagenes">
              {this.props.proyecto.DescriptionImages.sort((a, b) =>
                a.name > b.name ? 1 : -1
              ).map((img) => (
                <div className="imagen" key={img.name}>
                  {img.url.indexOf("mp4") === -1 ? (
                    <img src={img.url} alt="" />
                  ) : (
                    <Video video={img.url} controls={true} />
                  )}
                </div>
              ))}
            </div>
          </Container>
        </StyledBlack>
        <StyledGray className="gray">
          <Container className="gray-container">
            <div className="head">
              <h1>{this.props.proyecto.DesignTitle}</h1>

              <p>{this.props.proyecto.DesignDescription}</p>
            </div>
            <div className="imagenes">
              {this.props.proyecto.DesignImages.sort((a, b) =>
                a.name > b.name ? 1 : -1
              ).map((img) => (
                <div className="imagen" key={img.name}>
                  <img src={img.url} alt="" />
                </div>
              ))}
            </div>
          </Container>
          {
        this.props.proyecto.titleQr ? (
          <StyledBlueQR style={{backgroundColor: back ? back : "black"}}>
            <div className="textQr" style={{color: colorLetter ? colorLetter : "white"}}>
              {this.props.proyecto.titleQr ? (
                <h1>{this.props.proyecto.titleQr}</h1>
              ) : null}
              {this.props.proyecto.descriptionQr ? (
                <p>{this.props.proyecto.descriptionQr}</p>
              ) : null}
            </div>
            <div className="imageQr">
                <>
                  {this.props.proyecto.imgQr ? (
                    <img src={this.props.proyecto.imgQr.url} />
                  ) : null}
                  {this.props.proyecto.linkAppstore ||
                  this.props.proyecto.linkGoogle ? (
                    <div className="store_images">
                      <a href={this.props.proyecto.linkAppstore}>
                        <button>
                          <img src={appstore} />
                        </button>
                      </a>
                      <a href={this.props.proyecto.linkGoogle}>
                        <button>
                          <img src={googlestore} />
                        </button>
                      </a>
                      <a href={this.props.proyecto.linkWebsite }>
                        <button className="linkweb" style={{ color: this.props.proyecto.colorLetter ? "#11374F" : "white"}}>
                          VISITA EL SITIO WEB <img src={arrowLeft} alt="arrow" />
                      </button></a>
                    </div>
                  ) : null}
                </>
            </div>
          </StyledBlueQR>
        ) : null}
          <OtrosProyecto proyecto={this.props.proyecto} />

          <div className="Proyecto-footer">
            <InnovemosSection />
            <Footer />
          </div>
        </StyledGray>
      </StyledProyectoImages>
    );
  }
}

export default PrjectImages;
