import React, { Component } from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import SDK from "../../SDK";
import ProyectoPreview from "../../components/ProyectoPreview";

const StyledOtrosProyecto = styled.div`
  background: #ffffff;
  .otros_container {
    display: flex;
    flex-direction: column;

    h1 {
      font-family: Poppins-light;
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 60px;
    }

    .nota {
      margin: auto;
      margin-bottom: 60px;
    }
  }
`;

class OtrosProyecto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: null,
    };
  }

  async componentDidMount() {
    const proyectos = await SDK.getProyectos();
    const OtrosFilters = proyectos.filter(
      (element) => element.id !== this.props.proyecto.id
    );
    this.setState({
      projects: OtrosFilters,
    });
  }
  render() {
    return (
      <StyledOtrosProyecto>
        <Container className="otros_container">
          <h1>OTROS PROYECTOS RECOMENDADOS</h1>
          {this.state.projects
            ? this.state.projects.map((proy, idx) => {
                if (idx <= 1) {
                  return (
                    <ProyectoPreview
                      nota={proy}
                      idx={idx}
                      notas={this.state.projects}
                      key={idx}
                      suggest
                      proyecto
                    />
                  );
                }
              })
            : null}
        </Container>
      </StyledOtrosProyecto>
    );
  }
}

export default OtrosProyecto;
