import React, {Component, useRef, useState} from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import Trophy from "../../assets/images/trophy.png";
import Button from "../../components/Button";
import { AnimatedImage } from "../../components/AnimatedImage";

const StyledBadge = styled.div`
  /* *{
    border: 1px solid red;
} */
  
  display: flex;
  background-color: #A04D9A;
  color: #fff;
  /* or 100% */

  text-align: center;
  .container {
    flex-direction: column;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    line-height: 30px;
  }
  
  .trophy{
    margin-bottom: 30px;
    font-size: 30px;
    img {
      width: 120px;
      height: 104px;
      transform: translate3d(-50%¡, -50%, 0px);
      transition: transform 0.2s;
    }
  }
  
  Button{
   top: 40px; 
    margin-bottom: 20px;
  }

  @media (max-width: 1200px) {
    
    .container {
      flex-direction: column;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      line-height: 20px;
    }

    .trophy{
      margin-bottom: 30px;
      font-size: 30px;
      img {
        width: 120px;
        height: 104px;
      }
    }
  }

  @media (min-width: 390px) {
    
    .trophy{
      margin-bottom: 30px;
      font-size: 17px;
      img {
        width: 120px;
        height: 104px;
      }
    }

    Button{
      top: 30px;
    }
  }
`;

const ProjectBadge = () => {
    

        return (
            <StyledBadge>
                <Container className="container">
                    <div className="trophy">
                        <img src={Trophy} alt={""}  />
                        <h3>EFFIE BRONCE<br/>2018</h3>
                    </div>

                    <text>En febrero del 2018 ganamos un Effie Bronce en categoría de Servicios Financieros con la campaña de <b>INFONAVIT</b> “Piensa en ti” que tenía como objetivo principal ofrecer créditos hipotecarios a jóvenes mexicanos.
                        <br/>
                        <br/>
                        Le hablamos a trabajadores formales en México de 24 a 34 años, millennials con una vida digital.
                        <br/>
                        <br/>
                        El objetivo era otorgar 365,000 créditos hipotecarios a nivel nacional y superamos la meta en un 51% con 553,886 créditos, además aumentamos un 10% el Poppinso promedio de hipoteca vs. el 2016 e incrementamos la participación del nivel C en un 25% vs. el 2016
                    </text>

                    <div>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                window.open('https://www.effie.org/case_database/case/MX_2018_111');
                            }
                            }
                            whiteFont
                            button_text="VER PREMIO"
                        />
                    </div>
                </Container>
            </StyledBadge>
        );

}

export default ProjectBadge;

