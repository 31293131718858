import React, { Component } from "react";

export default class Link extends Component {
  render() {
    // console.log(this.props);
    return (
      <a
        style={this.props.style}
        onClick={() => (window.location = this.props.to)}
      >
        {this.props.children}
      </a>
    );
  }
}
