import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import back_mobile from "../assets/images/legales_mobile.png";
import back_desktop from "../assets/images/legales_desktop.png";
import Button from "../components/Button";
import { ReactComponent as ArrowRight } from "../assets/images/Icon/arrowLeft.svg";
import AvisoPrivacidadClientes from "./AvisoPrivacidadClientes";
import AvisoPrivacidadEmpleados from "./AvisoPrivacidadEmpleados";
import ADPClientes from "../assets/PDF/AVISO_DE_PRIVACIDAD_CLIENTES.pdf";
import ADPEmpleados from "../assets/PDF/AVISO_DE_PRIVACIDAD_EMPLEADOS.pdf";

const slideImage = keyframes`
    from {
      margin-top: 100%;
      width: 300%;
      height: 200%;
    }
    to {
      margin-top: 0%;
      width: 100%;
      height: 00%;
    }
        `;

const slidein2 = keyframes`
  0%{
    opacity: 0;
    width: 0%;
    margin-left: 100%;
  }
  100%{
    opacity: 1;
    width: 100%;
    margin-left: 0%;
  }
`;
const StyledAviso = styled.div`
  background: #000000;
  min-height: 100vh;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  width: 100%;

  hr {
    border: 1px solid white;
    display: none;
  }
  .container-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation-duration: 1s;
    animation-name: ${slideImage};
    animation-delay: 1.3s;
    .img_desktop {
      display: none;
    }
    img {
      width: 100%;
    }
    p {
      animation-duration: 3s;
      animation-name: ${slidein2};
      animation-delay: 1s;
      position: absolute;
      margin: auto 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 900;
      font-size: 52px;
      line-height: 54px;
      text-align: center;
      color: #ffffff;
    }
  }
  .container-buttons {
    padding: 3rem 2rem;
    .download {
      padding-top: 20px;
      padding-bottom: 50px;
      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: white;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-decoration: none;
      }
      svg {
        cursor: pointer;
      }
    }
    .border-button {
      border: 3px solid white;
      padding: 1rem;
    }
  }
  @media (min-width: 1200px) {
    position: relative;
    hr {
      display: block;
      display: flex;
      max-height: 230px;
      margin-top: -20px;
      position: relative;
    }
    .container-img {
      .img_mobile {
        display: none;
        position: relative;
      }
      .img_desktop {
        transition-delay: 4s;
        animation-duration: 2s;
        animation-name: ${slideImage};
        animation-delay: 0.8s;
        display: block;
        display: flex;
        width: 100%;
        padding-top: 188px;
      }
      p {
        animation-duration: 3s;
        animation-name: ${slidein2};
        animation-delay: 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 188px;
      }
      margin-bottom: 100px;
    }
    .container-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 0;
      padding: 3rem 3rem;
      hr {
        height: 230px;
      }
      .download {
        padding-bottom: 0;
      }
      .border-button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-height: 130px;
        max-width: 696px;

        p {
          max-width: 334px;
          font-size: 30px;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .container-buttons {
      padding: 3rem 3rem;
      .border-button {
        min-width: 500px;
      }
    }
  }
  @media (min-width: 1900px) {
    .container-buttons {
      .download {
        padding-bottom: 0;
      }
      .border-button {
        width: 696px;
      }
    }
  }
`;

export default function AvisoPrivacidad() {
  const [showTerms, setShowTerms] = useState(0);

  return (
    
    <StyledAviso>
      <>
        <div className="container-img">
          <img src={back_mobile} alt="back_mobile" className="img_mobile" />
          <img src={back_desktop} alt="back_desktop" className="img_desktop" />
          <p>Aviso de privacidad</p>
        </div>
        <div className="container-buttons">
          <div>
            <div className="border-button">
              <p>Aviso de privacidad para Clientes</p>
              <Button
                onClick={() => setShowTerms(1)}
                dark
                whiteFont
                button_text={"Leer"}
              />
            </div>

            <div className="download">
              <a href={ADPClientes} download>
                DESCARGAR AVISO DE PRIVACIDAD <ArrowRight />
              </a>
            </div>
          </div>
          <hr />
          <div>
            <div className="border-button">
              <p>Aviso de privacidad para Empleados</p>
              <Button
                onClick={() => {
                  setShowTerms(2);
                }}
                dark
                whiteFont
                button_text={"Leer"}
              />
            </div>

            <div className="download">
              <a href={ADPEmpleados} download>
                DESCARGAR AVISO DE PRIVACIDAD <ArrowRight />
              </a>
            </div>
          </div>
        </div>

        {showTerms === 0 ? null : showTerms === 1 ? (      
                  <AvisoPrivacidadClientes />
        ) : (
          <AvisoPrivacidadEmpleados />
        )}
      </>
    </StyledAviso>
  );
}
