import React, { Component } from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import Button from "../../components/Button";
import { Carousel } from "react-responsive-carousel";
import SDK from "../../SDK";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const StyledContact = styled.div`
  ${(props) => (props.topBottom ? "position: sticky;" : "position: relative;")}
  ${(props) => (props.topBottom ? "z-index: -1;" : "z-index: 1;")}

  bottom: 0;

  width: 100%;

  .carousel .slide {
    background: none !important;
    text-align: inherit !important;
    opacity: 0.1;
    transition: all 1s;
  }
  .carousel .slide.selected {
    opacity: 1;
  }
  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
    }
    form {
      display: flex;
      flex: 1;

      justify-content: center;
      align-items: center;
      flex-direction: column;

      margin-bottom: 3rem;

      input, select {
        width: 100%;
        background-color: white;
        border: none;
        border-bottom: 2px solid #000000;
        padding: 44px 0;

        color: #8b8b93;

        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 14px;
        color: #8b8b93;
        border-radius: 0;
      }
    }
    button {
      margin: 0px 0 20px 0;
    }
  }

  @media (max-width: 1920px) {
    .contac-container {
      position: relative;
      top: 90px;
    }
  }

  @media (max-width: 768px) {
    .contac-container {
      position: relative;
    }
    .columns {
      flex-direction: column;
      .column {
        h1,
        h2 {
          font-size: 18px;
          margin-bottom: 5px;
        }
        br {
          display: none;
        }
        form {
          color: red;
          input, select {
            padding: 20px 0;
          }
        }
      }
    }
  }
`;

const StyledFromSubmitted = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  max-width: 70%;
  padding-top: 50px;
  h2 {
    font-size: 60px;
    color: #7b56ad;
  }
  span {
    font-weight: 800;
    font-size: 60px;
    display: block;
    color: #232323;
  }
  p {
    font-weight: 400;
    font-size: 40px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 20px;
    }
    span {
      font-size: 30px;
    }
    div {
      flex-direction: column !important;
      margin-top: 20px;
      span {
        margin-bottom: 20px;
      }
    }
  }
`;

export default class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_submitted: false,
      form: {
        nombre: "",
        email: "",
        servicio: "",
        mensaje: "",
        "h-captcha-response": "",
      },
    };
  }

  async componentDidMount() {
    var servicios = await SDK.getServicios();
    servicios = servicios.sort((a, b) => (a.Indicador < b.Indicador ? -1 : 1));

    this.setState({
      servicios: servicios,
    });
  }

  componentDidUpdate() {
    if (this.props.servicios && !this.state.servicios) {
      this.setState({ servicios: this.props.servicios });
    }
  }
  formSubmit(e) {
    e.preventDefault();
    fetch("https://submit-form.com/oUtLLvw9D8DKJUg7NvWx-", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(this.state.form),
    })
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.error(error);
      });
    const form = {
      nombre: "",
      email: "",
      servicio: "",
      mensaje: "",
    };
    this.setState({ form_submitted: true, form });
  }

  inputChange(event) {
    var form = this.state.form;
    form[event.target.name] = event.target.value;
    this.setState({ form });
  }

  render() {
    return (
      <StyledContact topBottom={this.props.fixBottom}>
        <Container className="contac-container">
          <h4>/HABLEMOS</h4>
          <Carousel
            transitionTime={1000}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            selectedItem={!this.state.form_submitted ? 0 : 1}
            swipeable={false}
          >
            <div className="columns">
              <div className="column">
                <h1>hola@bahia360.mx</h1>
                <h2>
                  Hagamos algo
                  <br />
                  <span className="gradientText"> juntos.</span>
                </h2>
              </div>
              <div className="column">
                <form onSubmit={this.formSubmit.bind(this)}>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    placeholder="Introduce tu nombre."
                    required
                    value={this.state.form.nombre}
                    onChange={this.inputChange.bind(this)}
                  />
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Introduce tu e-mail."
                    required
                    value={this.state.form.email}
                    onChange={this.inputChange.bind(this)}
                  />
                  <select
                    name="servicio"
                    id="servicio"
                    required
                    value={this.state.form.servicio}
                    onChange={this.inputChange.bind(this)}
                  >
                    <option value="">Servicio que te interesa...</option>
                    {this.state.servicios
                      ? this.state.servicios.map((servicio, idx) => {
                        return (
                          <option key={idx} value={servicio.Titulo}>
                            {servicio.Titulo}
                          </option>
                        );
                      })
                      : null}
                  </select>
                  <input
                    type="text"
                    id="mensaje"
                    name="mensaje"
                    placeholder="¿Quieres decirnos algo?"
                    value={this.state.form.mensaje}
                    onChange={this.inputChange.bind(this)}
                    style={{marginBottom:30}}
                  />
                  <HCaptcha
                    sitekey="13d587af-e226-40be-84d1-2633113c1629"
                    onVerify={(token, ekey) => { var form = this.state.form; form["h-captcha-response"] = token; this.setState({ form }) }}
                  />
                  <Button dark button_text="ENVIAR" type="submit" />
                </form>
              </div>
            </div>
            <StyledFromSubmitted>
              <h2>¡Excelente!</h2>
              <span>Gracias por contactarnos.</span>
              <p>Nos pondremos en contacto contigo lo antes posible.</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    lineHeight: "14px",
                    fontWeight: 600,
                    marginRight: 40,
                  }}
                >
                  ¿Necesitas enviar otro?
                </span>
                <Button
                  onClick={() => {
                    this.setState({ form_submitted: false });
                  }}
                  dark
                  button_text="IR A FORMULARIO"
                />
              </div>
            </StyledFromSubmitted>
          </Carousel>
        </Container>
      </StyledContact>
    );
  }
}
