import React, { Component } from "react";
import styled from "styled-components";
import ARCO from "../assets/PDF/ARCO_BAHIA360.pdf";
import AvisoPrivacidad from "../assets/PDF/AVISO_DE_PRIVACIDAD_CLIENTES.pdf";

const StyledAviso = styled.div`
  background: #e2e2ea;
  padding: 0 3rem;
  width: 100%;
  color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  a {
    color: #3345da;
  }
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    color: black;
    text-align: center;
  }
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    .container_title{
      padding: 5rem;
    }
    .container_advice{
      padding: 3rem 7rem 3rem 0;
    }
    .title {
      font-size: 60px;
    }
  }
`;
export default class AvisoPrivacidadClientes extends Component {
  render() {
    return (
      <StyledAviso>
        <div className="container_title">
        <p className="title">Aviso de Privacidad para Clientes</p>
        </div>
        <div className="container_advice">
        <p>
          <strong>I. Identidad y domicilio del Responsable.</strong>
        </p>

        <p>
          Likeable Media México, SAPI. de C.V., conocido comercialmente como
          “Bahía 360”, con domicilio en Bahía de Santa Bárbara 145, Col.
          Verónica Anzures, CDMX, Alcaldía Miguel Hidalgo, CP 11300, es el
          responsable del tratamiento y protección de sus datos personales en
          términos del presente Aviso de privacidad.
        </p>

        <p>
          <strong>II. Datos personales que serán tratados.</strong>
        </p>
        <p>
          Para las finalidades especificadas en este aviso de privacidad, el
          responsable recaba de forma personal o directa de sus clientes
          potenciales y Suscriptores, a través de su sitio de Internet
          <a href="https://www.bahia360.mx/">https://www.bahia360.mx/</a>
          indirectamente a través de una fuente de acceso público permitida por
          la ley, los siguientes datos personales:
        </p>

        <p>
          Datos necesarios para el cumplimiento de las obligaciones derivadas de
          la relación comercial y prestación de servicios.
        </p>

        <p>
          <strong>Datos personales de identificación: </strong> nombre (s) y
          apellido (s), copia de identificación oficial, fecha de nacimiento,
          Registro Federal de Contribuyentes (RFC), firma autógrafa y/o
          electrónica, nombre completo y datos de contacto de referencias
          personales.
        </p>

        <p>
          <strong>Datos de contacto:</strong> domicilio y referencia
          domiciliaria, correo electrónico, números de teléfono de casa, móvil
          y/o recados.
        </p>

        <p>
          <strong>
            Datos relacionados con gustos y preferencias e intereses sociales:
          </strong>
          edad, estado civil, programa favorito, cantante o artista favorito,
          género de música, deporte, pasatiempo, bebida y comida favorita. Estos
          datos se pueden recabar respecto del Suscriptor o de sus familiares y
          se utilizarán exclusivamente para finalidades secundarias.
        </p>

        <p>
          <strong>Datos de seguridad y vigilancia: </strong>A toda persona que
          visita las instalaciones del responsable, se recaba directamente su
          fotografía y/o video, detalles del equipo de cómputo que traigan
          consigo, así como las imágenes de los titulares que sean captadas y
          grabadas mediante cámaras de seguridad localizadas en puntos
          específicos dentro y en el perímetro de los inmuebles donde existan
          cámaras de CCTV.
        </p>

        <p>
          Dichos datos son tratados bajo estrictas medidas de seguridad que
          garantizan su confidencialidad.
        </p>

        <p>
          Por ningún motivo el responsable recabará ni tratará datos personales
          de carácter sensible. Si por algún motivo el responsable llega a tener
          acceso a esta información la desechará de inmediato.
        </p>

        <p>
          <strong>
            III. Datos personales financieros y patrimoniales que serán
            tratados.
          </strong>
          <p>
            Para efecto de ofrecer al Suscriptor diferentes opciones de pago y/o
            instalación del servicio, el responsable puede recabar y tratar
            datos personales financieros y patrimoniales que incluyen datos de
            tarjeta de crédito o débito, situación en el buró de crédito,
            obligaciones susceptibles de valoración económica, tipo de vivienda
            (casa, local comercial o edificio), comprobante de domicilio, con
            ducto o sin ducto para cablear, tipo de construcción (madera, adobe,
            concreto, lámina u otro), datos físicos del domicilio (fachada).
          </p>
          <p>
            Dichos datos son tratados bajo estrictas medidas de seguridad que
            garanticen su confidencialidad
          </p>
        </p>

        <p>
          <strong>IV. Finalidades del tratamiento de datos personales.</strong>
        </p>

        <p>
          <strong>Primarias </strong>
          (Aquellas que dan origen y son necesarias para la existencia,
          mantenimiento y cumplimiento de la relación jurídica entre el titular
          y el responsable).
        </p>

        <p>
          Sus datos personales de identificación, contacto, la infraestructura
          tecnológica y de servicios, financieros y patrimoniales, serán
          tratados para: i) su registro como Suscriptor; ii) proveer el servicio
          de televisión restringida vía satélite; iii) informarle sobre cambios
          en el servicio originalmente contratado; iv) realizar gestiones de
          cobranza del Servicio, a través de visitas presenciales, llamadas
          telefónicas, mensajes o cualquier otro medio de comunicación; v)
          realizar instalaciones, desinstalaciones y/o servicios técnicos, con
          familiares y/o personas relacionadas con el suscriptor cuando el mismo
          no se encuentre en su domicilio y vi) emisión de facturas electrónicas
          relacionada con el Servicio.
        </p>

        <p>
          <strong>Secundarias </strong>
          (Aquellas que NO son necesarias para la relación jurídica entre el
          titular y el responsable).
        </p>

        <p>
          Los datos personales de identificación, de contacto, de gustos y
          preferencias e intereses sociales, características físicas y de
          seguridad y vigilancia, podrán utilizarse para: i) realizar gestiones
          de localización con las referencias personales proporcionadas por el
          suscriptor, cuando el mismo no sea ubicado en los datos de contacto
          proporcionados, así como dejarle recados, incluyendo saldos por
          adeudos de su servicio; ii) llevar a cabo rifas, encuestas, sorteos,
          concursos, ofertas, programas, actividades publicitarias, promociones
          y otorgamiento de cortesías; iii) verificar la identidad de los
          participantes y ponerse en contacto por cualquier medio con los
          ganadores; iv) envío de promociones, newsletters o información de
          socios comerciales del responsable y/o terceros que tengan alguna
          relación jurídica con el responsable; v) envío de productos
          promocionales, obsequios y/o premios, información y muestras; vi)
          permitir el acceso, control y videovigilancia/grabación para la
          seguridad de las personas que visitan las instalaciones del
          responsable y vii) evaluar la calidad del Servicio y viii) ofrecerle
          nuevas opciones de programación (paquetes) y pago del Servicio.
        </p>

        <p>
          Además, su información, podrá utilizarse para fines de mercadotecnia,
          publicidad y prospección comercial, específicamente para el
          ofrecimiento y la promoción de bienes, productos y servicios del
          responsable.
        </p>

        <p>
          Se hace del conocimiento al titular de los datos personales, que
          cuenta con un plazo de 5 (cinco) días hábiles para manifestar su
          negativa al tratamiento de sus datos personales, para todas o algunas
          de las finalidades secundarias, sin que ello tenga como consecuencia
          la conclusión del tratamiento para las finalidades primarias, enviando
          un correo electrónico a hola@bahia360.mx
        </p>

        <p>
          <strong>V. Transferencias de datos personales.</strong>
          <p>
            Con fundamento en lo dispuesto en el artículo 37 de la Ley Federal
            de Protección de Datos Personales en Posesión de los Particulares,
            el responsable podrá llevar a cabo las siguientes transferencias de
            datos personales sin autorización del titular.
          </p>
        </p>

        <ul>
          <li>
            A instituciones bancarias, prestadoras de servicios o empresas
            comerciales, con la finalidad de que ofrezcan nuevas opciones para
            el pago del servicio.
          </li>
          <li>
            Autoridades administrativas y órganos jurisdiccionales, con la
            finalidad de dar cumplimiento a sus requerimientos para la
            procuración o administración de justicia.
          </li>
          <li>
            Cuando la transferencia sea efectuada a sociedades controladoras,
            subsidiarias, filiales o afiliadas bajo el control común del
            responsable, o a una sociedad matriz o a cualquier sociedad del
            mismo grupo del responsable que opere bajo los mismos procesos y
            políticas.
          </li>
        </ul>

        <p>
          Con fundamento en el artículo 36 de la Ley Federal de Protección de
          Datos Personales en Posesión de los Particulares, el responsable podrá
          llevar a cabo las siguientes transferencias de datos personales de
          identificación y contacto del titular:
        </p>

        <ul>
          <li>
            Socios comerciales del responsable y/o terceros que tengan alguna
            relación jurídica con el responsable, con la finalidad de que estos
            les ofrezcan sus productos y/o servicios.
          </li>
        </ul>

        <p>
          <strong>
            VI. Medios y procedimiento para revocar el consentimiento y ejercer
            Derechos ARCO.
          </strong>
          <p>
            Usted tiene derecho de acceder a sus datos personales en posesión
            del responsable y a conocer los detalles del tratamiento de los
            mismos, así como a rectificarlos en caso de que su información
            personal este desactualizada, sea inexacta o incompleta; cancelarlos
            cuando considere que los mismos no están siendo tratados conforme a
            los principios, deberes y obligaciones previstos en la ley, o bien,
            oponerse al tratamiento de los mismos para fines específicos,
            siempre y cuando los mismos no sean necesarios para el cumplimiento
            de una obligación legal, (los “Derechos ARCO”).
          </p>
          <p>
            Asimismo, podrá revocar el consentimiento otorgado al responsable
            para el tratamiento de sus datos personales siempre y cuando los
            mismos no sean necesarios para la existencia, mantenimiento y
            cumplimiento de su relación jurídica con el responsable y/o
            cuestiones de seguridad.
          </p>
        </p>

        <p>
          <strong>
            A. Requisitos de la Solicitud de revocación del consentimiento y de
            ejercicio de derechos ARCO.
          </strong>
        </p>
        <p>
          En términos de lo señalado por la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares, toda Solicitud de
          revocación de consentimiento y ejercicio de derechos ARCO, deberá
          contener y acompañar lo siguiente:
        </p>

        <ol>
          <li>
            Señalar el nombre del titular de los datos y su domicilio, u otro
            medio para comunicarle la respuesta a su solicitud.
          </li>
          <li>
            Acompañar los documentos que permitan acreditar su identidad, o en
            su caso, la representación del titular de los datos.
          </li>
          <li>
            Señalar una descripción clara y precisa de los datos personales
            respecto de los que busca ejercer su derecho de revocación de
            consentimiento o alguno de los Derechos ARCO. Si se trata de
            rectificación de datos personales deberá indicar las modificaciones
            a realizarse y aportar los documentos que sustenten su petición; y
          </li>
          <li>
            Señalar y/o acompañar cualquier otro elemento o documento que
            facilite la localización de los datos personales.
          </li>
        </ol>

        <p>
          Para ejercer su derecho de revocación de consentimiento, así como sus
          derechos ARCO, usted podrá apoyarse en el Formato que se encuentra
          disponible en el sitio de internet:
          <a href={ARCO} target="_blank">
            https://www.bahia360.mxBahia_formatoARCO
          </a>
          (en la sección Formato de Solicitud ARCO).
        </p>

        <p>
          <strong>
            B. Medios para presentar las solicitudes de revocación de
            consentimiento y ejercicio de derechos ARCO.
          </strong>
        </p>

        <p>
          Con el fin de que la presentación de las Solicitudes de revocación de
          consentimiento y ejercicio de derechos ARCO sea sencilla, los
          titulares de los datos podrán presentar dichas solicitudes por las
          siguientes vías:
        </p>

        <ol>
          <li>
            Por correo certificado con acuse de recibo al domicilio del
            responsable.
          </li>
          <li>
            Por correo electrónico a la dirección {" "}
            <a href="mailto: datospersonales@bahia360.mx ">
              
              datospersonales@bahia360.mx
            </a>
            siempre y cuando sea posible identificar fehacientemente al titular
            mediante mecanismos de autenticación permitidos por las
            disposiciones legales de la materia, y aceptados por el responsable.
            La utilización de firma electrónica avanzada o del instrumento
            electrónico que lo sustituya, eximirá de la presentación de la copia
            del documento de identificación. Se acusará recibo por correo
            electrónico indicando la fecha de la recepción de la Solicitud de
            revocación de consentimiento o ejercicio de derechos ARCO.
          </li>
        </ol>

        <p>
          <strong>
            C. Plazo de atención de solicitudes de revocación de consentimiento
            y ejercicio de derechos ARCO.
          </strong>
        </p>

        <p>
          La recepción de una Solicitud de revocación de consentimiento y
          ejercicio de derechos ARCO no implica que se declare procedente por
          parte del responsable. En caso de que la información proporcionada en
          la Solicitud de ejercicio de derechos ARCO sea insuficiente o errónea
          para atenderla, o bien no se acompañen los documentos necesarios para
          dar trámite a la misma, el responsable requerirá al titular de los
          datos o su representante, dentro de los 5 días hábiles siguientes a la
          recepción de la Solicitud de ejercicio de derechos ARCO, por una única
          vez, que aporte los elementos o documentos necesarios para dar trámite
          a la misma. El titular de los datos o su representante contarán con 10
          días hábiles contados a partir del día siguiente de la recepción del
          requerimiento, para darle respuesta.
        </p>

        <p>
          El responsable dará respuesta a la Solicitud de revocación de
          consentimiento o ejercicio de derechos ARCO con la determinación
          alcanzada, en un plazo de 20 días hábiles contados a partir de la
          recepción de la solicitud, o en caso de haberse solicitado información
          o documentos adicionales, en un plazo de 20 días hábiles contados a
          partir del día siguiente a la presentación de la respuesta al
          requerimiento, para el caso de ejercicio de derechos ARCO.
        </p>

        <p>
          En caso de ser procedente la Solicitud de revocación de consentimiento
          o ejercicio de derechos ARCO, el responsable hará efectiva la
          determinación alcanzada dentro de un plazo de 15 días hábiles contados
          a partir de que se comunique la respuesta al titular de los datos o su
          representante. Tratándose de solicitudes de acceso a sus datos
          personales, la entrega de los mismos se hará previa acreditación de la
          identidad del titular de los datos o su representante.
        </p>

        <p>
          El responsable podrá ampliar los plazos para dar respuesta a una
          Solicitud de ejercicio de derechos ARCO, y/o para hacer efectiva su
          determinación alcanzada, por una sola vez, por periodos iguales a los
          señalados en cada caso, siempre que el Departamento de Datos
          Personales considere que las circunstancias del caso lo justifican.
          Ante tales supuestos, el responsable le notificará al titular de los
          datos o su representante, la (s) circunstancia (s) que justifican la
          ampliación, dentro de cada uno de los plazos originales para dar
          respuesta o hacer efectiva la determinación alcanzada.
        </p>

        <p>
          Las respuestas a las solicitudes de revocación de consentimiento o
          ejercicio de derechos ARCO se entregarán al titular de los datos o su
          representante, por correo electrónico o por correo certificado. La
          información o datos personales solicitados podrán ser puestos a
          disposición del titular de los datos o su representante, en copias
          simples o en archivo electrónico según el tipo y la cantidad de
          documentos de que se trate cada caso.
        </p>

        <p>
          <strong>El Comité de Datos Personales </strong>
          será el encargado de dar trámite a las solicitudes de revocación de
          consentimiento y/o para el ejercicio de derechos ARCO, así como
          atender cualquier duda que pudiera tener respecto al tratamiento de su
          información, contactando a dicho comité a través los siguientes
          medios: i) Por correo certificado con acuse de recibo, al domicilio
          del responsable; y ii) por correo electrónico a la dirección {" "}
          <a href="mailto: datospersonales@bahia360.mx ">
            
            datospersonales@bahia360.mx
          </a>
        </p>

        <p>
          <strong>
            VII.
            Opcionesymediosparalimitarelusoodivulgacióndelosdatospersonales.
          </strong>
        </p>

        <p>
          Con el objeto de que Usted pueda limitar el uso y divulgación de su
          información, se ponen a disposición los siguientes medios:
        </p>

        <ul>
          <li>
            Su Inscripción en el Registro Público para Evitar Publicidad
            (REPEP), que está a cargo de la Procuraduría Federal del Consumidor
            (PROFECO), con la finalidad de no recibir publicidad vía telefónica
            de proveedores de sectores de: i) telecomunicaciones, ii) turismo y
            iii) comercial, ni que su información sea utilizada con fines
            mercadotécnicos o publicitarios. Para mayor información sobre este
            registro, usted puede consultar el portal de internet de la PROFECO{" "}
            <a href="www.profeco.gob.mx">www.profeco.gob.mx</a> o bien ponerse
            en contacto directo con ésta.
          </li>

          <li>
            Enviar un correo electrónico a la dirección 
            <a href="mailto: datospersonales@bahia360.mx">
              {" "}datospersonales@bahia360.mx
            </a>
            manifestando su negativa a seguir recibiendo publicidad por parte
            del responsable.
          </li>
        </ul>

        <p>
          <strong>VIII. Cookies.</strong>
        </p>

        <p>
          Le informamos que a través de nuestro sitio de Internet
          <a href="https://bahia360.mx/">{" "}https://bahia360.mx/ </a>, utilizan
          cookies. Los datos personales que se obtienen a través de estas
          tecnologías son: i) la dirección IP, ii) versión del navegador, iii)
          sistema operativo, iv) identificador único. Mismos que utilizamos
          para: i) identificar el país del visitante, ii) idioma del navegador,
          iii) tiempo promedio de estancia en el sitio, iv) número de visitas
          por día y mes, v) secciones que visita dentro del sitio, vi) en que
          banners da clic y vii) generar reportes analíticos y estadísticos.
        </p>

        <p>
          Estás tecnologías podrán deshabilitarse, dependiendo del navegador,
          siguiendo los siguientes pasos:
        </p>

        <ol>
          <li>
            Buscar en la sección de ayuda del navegador la manera de desactivar
            cookies;
          </li>
          <li>Directo en la herramienta de configuración del navegador.</li>
        </ol>

        <p>
          Para mayor información sobre el uso de estas tecnologías, puede
          consultar el sitio de Internet de su navegador.
        </p>

        <p>
          <strong>IX. Cambios o modificaciones al Aviso de privacidad.</strong>
        </p>

        <p>
          El presente aviso puede sufrir modificaciones, cambios o
          actualizaciones en cualquier momento, derivado de nuevos
          requerimientos legales; de nuestras propias necesidades por el
          servicio que ofrecemos, en cuyo caso se hará de su conocimiento a
          través del sitio web:
          <a href="https://bahia360.mx"> https://bahia360.mx </a> en la sección
          Aviso de Privacidad Integral.
        </p>

        <p>
          El procedimiento a través del cual se llevarán a cabo las
          notificaciones sobre modificaciones, cambios o actualizaciones al
          presente aviso de privacidad será a través de la publicación en el
          sitio web señalado anteriormente.
        </p>
        <p>
          <strong>X.Consentimiento</strong>
        </p>
        <p>
          Para dar consentimiento favor de descargar el <a href={AvisoPrivacidad} download>archivo adjunto</a>,
          firmarlo y enviarlo por correo electrónico a la dirección
          <a href="mailto: hola@bahia360.mx"> hola@bahia360.mx </a>
        </p>
        </div>
      </StyledAviso>
    );
  }
}
