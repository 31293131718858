import React, { Component } from "react";
import styled from "styled-components";
// Componentes
import Hero from "./sections/Hero";
import NuestrosServiciosPlx from "./sections/NuestrosServiciosPlx";
import NuestrosProyectos from "./sections/NuestrosProyectos";
import BlogPrev from "./sections/BlogPrev";
import Contacto from "./sections/Contacto";
import Certificacion from "./sections/Certification";
import Footer from "./sections/Footer";
import { Helmet } from "react-helmet";

const StyledHome = styled.div`
  height: 100vh;
`;

export default class Home extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <StyledHome>
        <Helmet>
          <meta
            name="description"
            content="Creamos innovación tecnológica y aplicamos el análisis de datos a las estrategias de comunicación digital. Posiciona tu marca: sé parte de Bahía."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:url" content={`http://new.bahia360.mx`} />

          {/* <meta
            property="og:image"
            // TODO: CHANGE THIS URL
            // content="https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/"
          /> */}
          <meta name="twitter:url" content={`http://new.bahia360.mx`} />
          <meta name="twitter:title" content={`Bahia - Fueled by People`} />
          {/* <meta
            name="twitter:description"
            // TODO: CHANGE THIS URL
            // content="https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/"
          /> */}
          {/* <meta
            name="twitter:image"
            // TODO: CHANGE THIS URL
            // content="https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/"
          /> */}

          <meta
            name="keywords"
            content="comunicación estratégica, Smart KPI, KPIs, innovacion digital. "
          />
        </Helmet>
        <Hero />
        <NuestrosServiciosPlx isBlack={false} />
        <NuestrosProyectos />
          <Certificacion />
        <BlogPrev />
        <Contacto fixBottom={true} />
        <Footer />
      </StyledHome>
    );
  }
}
