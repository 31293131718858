import React, { Component } from "react";
import Container from "../../components/Container";
import styled from "styled-components";
import Link from "../../components/Link";
import Button from "../../components/Button";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const MobileServices = styled.section`
  ${(props) => (props.isBlack ? "background: black;" : "background: white;")}
  display: none;
  @media (max-width: 991.98px) {
    display: block;
  }
`;

const SelectorServices = styled.section`
  margin-top: 15px;
  .service {
    p {
      ${(props) => (props.isBlack ? "color: #383838;" : "color: #e2e2ea;")}

      margin: 0;
      margin-bottom: 10px;
      cursor: pointer;
      z-index: 13;
    }

    .current {
      ${(props) => (props.isBlack ? "color: #FFFFFF;" : "color: #232323;")}
    }

    &:last-child {
      p {
        margin-bottom: 00px;
      }
    }
  }
`;

const Services = styled.section`
  margin-top: 15px;
  .carousel .slide {
    ${(props) => (props.isBlack ? "background: black;" : "background: white;")}

    margin-top: 15px;
    .service {
      .infoSection {
        text-align: start;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          ${(props) => (props.isBlack ? "color: #FFFFFF;" : "color: #232323;")}
        }
        button {
          margin-left: 10px;
        }
      }
    }
  }
`;

const StyledButtons = styled.section`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;

  *:focus {
    outline: none !important;
  }

  h3 {
    cursor: pointer;
    ${(props) => (props.isBlack ? "color: #FFFFFF;" : "color: #232323;")}

    margin: 10px;
    position: relative;
    font-size: 15px;
  }

  .disabled {
    opacity: 0.5;
  }
`;

const StyledButtonRight = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  span {
    color: #a04d9a;
    font-weight: bold;
  }
`;
const StyledButtonLeft = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  span {
    color: #a04d9a;
    font-weight: bold;
  }
`;

class NuestrosServicios extends Component {
  constructor(props) {
    super(props);
    this.state = { avaliableServices: this.props.services, currentSlide: 0 };
  }

  nextSlide = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    });
  };

  prevSlide = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    });
  };

  changeSlide(idx) {
    this.setState({
      currentSlide: idx,
    });
  }

  updateCurrentSlide = (idx) => {
    const { currentSlide } = this.state;

    if (currentSlide !== idx) {
      this.setState({
        currentSlide: idx,
      });
    }
  };

  render() {
    return this.state.avaliableServices ? (
      <MobileServices isBlack={this.props.isBlack}>
        <Container className="services-container">
          <h4>/Nuestros servicios</h4>
          <SelectorServices isBlack={this.props.isBlack}>
            {this.state.avaliableServices.map((service, idx) => {
              return (
                <div className={`service`} key={idx}>
                  <p
                    className={this.state.currentSlide === idx ? "current" : ""}
                    onClick={() => this.changeSlide(idx)}
                  >
                    {service.Titulo}
                  </p>
                </div>
              );
            })}
          </SelectorServices>

          <Services isBlack={this.props.isBlack}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              selectedItem={this.state.currentSlide}
              onChange={this.updateCurrentSlide}
            >
              {this.state.avaliableServices.map((service, idx) => {
                return (
                  <div className="service" key={idx}>
                    <img src={service.Imagen.url} alt={service.Titulo} />
                    <div className="infoSection">
                      <h3>
                        <b>/{service.Indicador}</b>
                      </h3>
                      <h2 className="gradientText">{service.Titulo}</h2>
                      <p>{service.Descripcion}</p>
                      {this.props.servicioActual != service.id ? (
                        <Link to={`/servicio/${service.slug}`}>
                          <Button
                            button_text="CONOCE MÁS"
                            dark
                            whiteFont={this.props.isBlack}
                          ></Button>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Services>
          <StyledButtons
            isBlack={this.props.isBlack}
            style={
              this.state.currentSlide + 1 == this.state.avaliableServices.length
                ? { justifyContent: "flex-start" }
                : null
            }
          >
            {this.state.currentSlide > 0 ? (
              <StyledButtonLeft>
                <span>&larr;</span>
                <h3 onClick={() => this.prevSlide()} className="servicePrev">
                  {
                    this.state.avaliableServices[this.state.currentSlide - 1]
                      .Titulo
                  }
                </h3>
              </StyledButtonLeft>
            ) : null}
            {this.state.currentSlide + 1 <
            this.state.avaliableServices.length ? (
              <StyledButtonRight>
                <h3 onClick={() => this.nextSlide()} className="serviceNext">
                  {
                    this.state.avaliableServices[this.state.currentSlide + 1]
                      .Titulo
                  }
                </h3>
                <span>&rarr;</span>
              </StyledButtonRight>
            ) : null}
          </StyledButtons>
        </Container>
      </MobileServices>
    ) : null;
  }
}

export default NuestrosServicios;
