import React from "react";
import styled from "styled-components";
import Contacto from "./sections/Contacto";
import Footer from "./sections/Footer";
import { Helmet } from "react-helmet";

const StyledContactUs = styled.section`
  padding-top: 8rem;

  @media (max-width: 768px) {
    padding-top: 2rem;
  }
`;

export default function Contactanos() {
  return (
    <StyledContactUs>
      <Helmet>
        <meta
          name="description"
          content="Aplica estrategias de posicionamiento en comunicación digital efectivas. Escribe a hola@bahia360.mx "
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:url" content={`http://new.bahia360.mx`} />

        <meta name="twitter:url" content={`http://new.bahia360.mx`} />
        <meta name="twitter:title" content={`Bahia - Fueled by People`} />

        <meta
          name="keywords"
          content="posicionar marca, mejorar comunicación "
        />
      </Helmet>
      <Contacto fixBottom={false} />
      <Footer />
    </StyledContactUs>
  );
}
