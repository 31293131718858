import React, { Component } from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Link from "../../components/Link";

import ScrollIndicator from "../../components/ScrollIndicator";
import IMG from "../../assets/images/bahía_gif_over.gif";

const StyledInovemos = styled.section`
  background: #000000;
  color: #ffffff;

  .elevemos-container {
    position: relative;
  }

  .art {
    display: none;
    z-index: 2;
  }
  .info-section {
    // max-width: 500px;
    position: relative;
    width: 100%;
    h1 {
      position: relative;

      font-family: Poppins-light;
      font-weight: 300;
      margin: 0;
      margin-bottom: 40px;
      z-index: 2;
    }

    h3 {
      position: relative;

      font-family: Poppins-light;
      margin-bottom: 40px;
      z-index: 2;
    }
  }
  .gif-container {
    position: absolute;
    width: 200px;
    /* height: 300px; */
    top: 0;
    left: 0;

    z-index: 1;
    pointer-events: none;

    img {
      max-width: 100%;
    }
    .v-lines {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .v-line {
        height: 100%;
        width: 20%;
        display: inline-block;
        background-color: black;
        float: left;
        transform: scaleX(1);
        transition: all 0.6s;
      }
    }

    .h-lines {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .h-line {
        height: 20%;
        width: 100%;
        display: inline-block;
        background-color: black;
        float: left;
        transform: scaleY(1);
        transition: all 0.6s;
      }
    }
  }

  .active {
    .v-lines {
      .v-line {
        transform: scaleX(0);
      }
    }

    .h-lines {
      .h-line {
        transform: scaleY(0);
      }
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .elevemos-container {
      display: flex;
      height: 500px;
      padding-bottom: 4rem;
    }

    .info-section {
      // max-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 30px;
    }

    .art {
      display: block;
      width: 50%;
      max-width: 400px;
      position: relative;
    }

    .rectangle {
      position: absolute;
      width: 200px;
      height: 325px;
      bottom: 0;
    }

    .red {
      background: #ff5353;
      top: 0;
      right: 30px;
    }

    .blue {
      background: #5f52f7;
      top: 50px;
      right: 70px;
    }

    .green {
      background: #a3ffa6;

      top: 100px;
      right: 120px;
    }

    .white {
      background: #ffffff;
      right: 55px;
      bottom: -10px;
    }
  }
`;

class ElevemosMarca extends Component {
  constructor() {
    super();
    this.state = {
      isGifVisible: false,
    };
    this.container = React.createRef();
  }

  setStateGif(state) {
    this.setState({
      isGifVisible: state,
    });
  }

  componentDidMount() {
    // let container = document.getElementById("info-section");
    let container = this.container.current;
    // console.log(container);
    let image = document.getElementById("gif-container");

    container.addEventListener("mouseenter", this.setStateGif.bind(this, true));

    container.addEventListener(
      "mouseleave",
      this.setStateGif.bind(this, false)
    );

    container.addEventListener("mousemove", (e) => {
      var y = e.clientY - container.getBoundingClientRect().y;
      var x = e.clientX - container.getBoundingClientRect().x;

      const gifWidth = image.clientWidth / 2;
      const gifHeight = image.clientHeight / 2;

      var correccionX =
        x < gifWidth
          ? x - gifWidth
          : x + gifWidth - container.getBoundingClientRect().width > 0
          ? x + gifWidth - container.getBoundingClientRect().width
          : 0;

      var correccionY =
        y < gifHeight
          ? y - gifHeight
          : y + gifHeight - container.getBoundingClientRect().height > 0
          ? y + gifHeight - container.getBoundingClientRect().height
          : 0;

      image.style.top = `${y - gifHeight - correccionY}px`;
      image.style.left = `${x - gifWidth - correccionX}px`;
    });
  }

  componentWillUnmount() {
    let container = this.container.current;
    container.removeEventListener(
      "mouseenter",
      this.setStateGif.bind(this, true)
    );

    container.removeEventListener(
      "mouseleave",
      this.setStateGif.bind(this, false)
    );
  }

  render() {
    // console.log(this.state);
    return (
      <StyledInovemos>
        <Container className="elevemos-container">
          <ScrollIndicator top />

          <div
            className="info-section info"
            id="info-section"
            ref={this.container}
          >
            <h1>
            Elevamos y transformamos tu marca a través de la innovación.
            </h1>
            <h3>Somos expertos en tendencias digitales para tu negocio</h3>
            <Link to="/servicios" style={{ zIndex: 2, position: "relative" }}>
              <Button button_text="CONOCE MÁS" dark whiteFont={true}></Button>
            </Link>

            <div
              className={`${
                this.state.isGifVisible ? "active" : ""
              } gif-container`}
              id="gif-container"
            >
              <img src={IMG} alt="" />

              <div className="v-lines">
                <div className="v-line"></div>
                <div className="v-line"></div>
                <div className="v-line"></div>
                <div className="v-line"></div>
                <div className="v-line"></div>
              </div>

              <div className="h-lines">
                <div className="h-line"></div>
                <div className="h-line"></div>
                <div className="h-line"></div>
                <div className="h-line"></div>
                <div className="h-line"></div>
              </div>
            </div>
          </div>

          {/* <div className="art">
            <div className="rectangle red"></div>
            <div className="rectangle blue "></div>
            <div className="rectangle green"></div>
            <div className="rectangle white"></div>
          </div> */}
        </Container>
      </StyledInovemos>
    );
  }
}

export default ElevemosMarca;
