import styled from "styled-components";

const Container = styled.div`
  height: auto;
  min-height: 100%;
  max-width: 1300px;
  background-color: ${(props) => props.background_color || "inherit"};

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    margin: 0 auto;
    padding: 50px;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    margin: 0 auto;
    padding: 50px;
  }
  
  max-width: 1300px;

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    margin: 0 auto;
    padding: 30px;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    margin: 0 auto;
    padding: 50px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    margin: 0 auto;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin: 0 auto;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    margin: 0 auto;
  }
`;

export default Container;
