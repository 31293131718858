import React, { Component } from "react";

import styled from "styled-components";
import { Helmet } from "react-helmet";
import InnovemosSection from "./sections/InnovemosSection";
import NuestrosServicios from "./sections/NuestrosServiciosPlx";
import Footer from "./sections/Footer";

const StyledServices = styled.div`
  background: #000000;
  padding-top: 6rem;
`;

export default class Servicios extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Análisis de negocio, arquitectura de datos y diseño para la creación de apps, sitios web, programas de fidelidad y gestión de redes sociales."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:url" content={`http://new.bahia360.mx`} />
          <meta name="twitter:url" content={`http://new.bahia360.mx`} />
          <meta name="twitter:title" content={`Bahia - Fueled by People`} />
          <meta
            name="keywords"
            content="mkt, redes sociales más usadas, publicidad en instagram, anuncios facebook, crear sitio web, consultoría de marketing, programas de fidelidad, diseño de apps, SEO SEM, community manager"
          />
        </Helmet>
        <StyledServices>
          <NuestrosServicios isBlack={true} />
        </StyledServices>
        <InnovemosSection />
        <Footer />
      </>
    );
  }
}
