import React, { Component } from "react";

import Footer from "./sections/Footer";
import InnovemosSection from "./sections/InnovemosSection";
import Clientes from "./sections/Clients";
import Elevemamos from "./sections/ElevemosMarca";
import Nosotros from "./sections/Nosotros";
import Header from "./sections/Header";
import SDK from "../SDK";
import { Helmet } from "react-helmet";

export default class About extends Component {
  constructor() {
    super();
    this.state = {
      clients: null,
      clientsPerPage: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    };
  }
  componentDidMount() {
    SDK.getClients().then((clients) => {
      var pages = [];
      for (
        var i = 0;
        i < clients.length / this.state.clientsPerPage.length;
        i++
      ) {
        pages.push(i);
      }
      this.setState({ clients, pages });
    });
  }
  render() {
    return (
      <>
        <Header
          h4="¿QUIÉNES SOMOS?"
          h2="INNOVATION HUB"
          h1="Construimos experiencias y solucionamos problemas a través del uso estratégico de la data."
        />

        <Helmet>
          <meta
            name="description"
            content="Somos una agencia de web marketing que se apoya en la arquitectura de datos, creatividad e innovación para conectar con las audiencias."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:url" content={`http://new.bahia360.mx`} />
          <meta name="twitter:url" content={`http://new.bahia360.mx`} />
          <meta name="twitter:title" content={`Bahia - Fueled by People`} />
          <meta
            name="keywords"
            content="innovación y transformación, técnicas de innovación, Business Intelligence, consultoria de marketing, estrategia de MKT, tendencias, KPIs, Smart KPI"
          />
        </Helmet>
        <Nosotros />
        <Elevemamos />
        <Clientes
          clients={this.state.clients}
          pages={this.state.pages}
          clientsPerPage={this.state.clientsPerPage}
        />
        <InnovemosSection />
        <Footer />
      </>
    );
  }
}
