import React, { Component } from "react";
import styled from "styled-components";

import Container from "../../components/Container";
import ProjectPreview from "../../components/projectPreview";
import ScrollIndicator from "../../components/ScrollIndicator";
import Video from "../../components/Video";
import SDK from "../../SDK";
import { Element } from "react-scroll";

export const StyledNuestrosProyectos = styled.div`
  background: #000000;
  color: white;
  position: relative;
  z-index: 100;

  padding-top: 50px;

  .proyectos-container {
    position: relative;
  }

  h4 {
    padding-bottom: 50px;
  }

  h2 {
    width: 50%;
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 100px 0;
    div:nth-child(2n) {
      margin-top: -150px;
    }
  }

  .behance {
    text-align: center;
    margin: auto;
    width: fit-content;
    margin-top: 50px;
  }

  p {
    color: white;
    margin: 0;
  }

  a {
    color: white;
    text-decoration: none;
    ::after {
      content: "";
      display: block;
      width: 0;
      height: 3px;
      background: #fff;
      transition: width 0.3s;
    }
    :hover::after {
      width: 100%;
      //transition: width .3s;
    }
  }

  h3 {
    text-transform: uppercase;
  }

  @media (max-width: 1200px) {
    h2 {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    h2 {
      width: 100%;
    }

    .columns {
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 51px;
        width: 100%;
        color: #ffffff;
      }
      div:nth-child(2n) {
        margin-top: -70px;
      }
    }
  }
`;

export default class NuestrosProyectos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlaying: false,
    };
  }

  async componentDidMount() {
    const proyectos = await SDK.getProyectos();
    this.setState({
      projects: proyectos,
    });
  }

  componentDidUpdate() {
    if (this.props.proyectos && !this.state.projects) {
      this.setState({ projects: this.props.proyectos });
    }
  }
  render() {
    var test = this.state.projects;
    function partir() {
      return test.slice(1, 5);
    }
    return this.state.projects ? (
      <Element id="_proyectos">
        <StyledNuestrosProyectos>
          <Container className="proyectos-container">
            <ScrollIndicator top />
            <h4>/Nuestros Proyectos</h4>
            <h2>
              Algunos de nuestros mejores{" "}
              <span className="gradientText">proyectos.</span>
            </h2>
            <div className="columns">
              {partir().map((project, idx) => {
                return (
                  <ProjectPreview
                    idx={idx + 1}
                    project={project}
                    key={project.id}
                  />
                );
              })}
            </div>
            <Video video="https://res.cloudinary.com/bahia360/video/upload/v1627504106/Bahi_I_a_manifiesto_f9b1099268.mp4" />

            <h3 className="behance">
              <a
                href="https://www.behance.net/bahiahub"
                target="_blank"
                rel="noopener noreferrer"
              >
                CONOCE NUESTRO BEHANCE{" "}
                <span style={{ color: "#A04D9A" }}>&rarr;</span>{" "}
              </a>
            </h3>
          </Container>
        </StyledNuestrosProyectos>
      </Element>
    ) : null;
  }
}
