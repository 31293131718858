import React from "react";
import styled from "styled-components";

const StyledwhitePaper = styled.div`
  @media (min-width: 992px) {
    .children {
      margin-top: 0rem;
    }
  }
  @media (min-width: 1200px) {
    .children {
    }
  }
  @media (min-width: 1400px) {
    .children {
    }
  }
`;

function whitePaper(props) {
  return (
    <StyledwhitePaper>
      <div className="children">{props.children}</div>
    </StyledwhitePaper>
  );
}

export default whitePaper;
