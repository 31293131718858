import React, { Component } from "react";
import SDK from "../../SDK";
import styled from "styled-components";
import Container from "../../components/Container";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import Entrada from "../../components/EntradaPreview";

const BlogContainer = styled.section`
  background: #e2e2ea;
  /* * {
    border: 1px solid red;
  } */
  .container {
    display: flex;
    flex-direction: column;
  }
  .header {
    /* margin-top: 40px; */
    margin-bottom: 40px;
  }
  .notas_container {
    margin-bottom: 50px;
    h4 {
      color: black;
      margin-bottom: 50px;
    }
    .notas {
      display: flex;
      flex-wrap: wrap;
    }
  }
  a {
    cursor: pointer;
    align-self: center;
    justify-self: center;
    text-decoration: none;
    color: black;
    margin-bottom: 5px;
    font-weight: bold;
    span {
      color: #a04d9a;
    }

    ::after {
      content: "";
      display: block;
      width: 0;
      height: 3px;
      background: black;
      transition: width 0.3s;
    }
    :hover::after {
      width: 100%;
      //transition: width .3s;
    }
  }

  @media (min-width: 768px) {
    .header {
      margin-bottom: 50px;
    }
    .notas_container {
      margin-bottom: 70px;
      h4 {
        margin-bottom: 70px;
      }
    }
    a {
      margin-bottom: 15px;
    }
  }
`;

class BlogPrev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notas: [],
    };
  }

  async componentDidMount() {
    var nots = await SDK.getArticulos();
    if (nots) {
      nots.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      this.setState({
        notas: nots.slice(0, 4),
      });

      if (this.props.suggest) {
        const result = nots.filter(
          (element) => element.id !== this.props.suggest.id
        );

        this.setState({
          notas: result,
        });
      }
    }
  }

  render() {
    return (
      <Element id="_trends">
        <BlogContainer>
          <Container className="container">
            {!this.props.suggest ? (
              <div className="header">
                <h2>Conoce lo más trendy</h2>
                <h2 className="gradientText">del mundo digital.</h2>
              </div>
            ) : null}

            <div className="notas_container">
              <h4>LO MÁS RECIENTE</h4>
              <div className="notas">
                {this.state.notas.map((nota, idx) => {
                  if (!this.props.suggest) {
                    return (
                      <Entrada
                        nota={nota}
                        idx={idx}
                        notas={this.state.notas}
                        key={idx}
                      />
                    );
                  } else {
                    if (idx <= 1) {
                      return (
                        <Entrada
                          suggest={this.props.suggest}
                          nota={nota}
                          idx={idx}
                          notas={this.state.notas}
                          key={idx}
                        />
                      );
                    }
                  }
                })}
              </div>
            </div>

            <a href="/blog">
              DESCUBRE OTRAS NOTAS <span>&rarr;</span>
            </a>
          </Container>
        </BlogContainer>
      </Element>
    );
  }
}

export default BlogPrev;
