import React, { Component } from "react";

import styled from "styled-components";
import ScrollIndicator from "../../components/ScrollIndicator";
import Container from "../../components/Container";
import image1 from "../../assets/images/aboutImg1.png";
import responsiveimg from "../../assets/images/responsive-img.jpg";
import IMG from "../../assets/images/aboutImg1.png";
import { withRouter } from "react-router-dom";
import Link from "../../components/Link";

const StyledHeader = styled.section`
  color: white;
  position: relative;
  background: #000000;
  padding-top: 60px;

  z-index: 1;

  .header-container {
    position: relative;

    .titles {
      .titles-container {
        width: 100%;
        margin: auto;
        img {
          margin-bottom: 45px;
          max-width: 110px;
        }
        .descripcion {
          h3 {
            margin: 0;
          }
        }

        .date {
          h3 {
            font-weight: 100;
          }
        }
      }
    }

    .titles-routes {
      display: flex;
      flex-direction: column;
      margin: 45px 0;
      a {
        cursor: pointer;
        color: #3345da;
        text-decoration: none;
        ::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;

          background: #3345da;
          transition: width 0.3s;
        }
        :hover::after {
          width: 100%;
          //transition: width .3s;
        }
      }
      h4 {
        margin: 0;
        text-transform: uppercase;
        /* cursor: pointer; */
      }
      /* h4:hover {
        text-decoration: underline;
        cursor: pointer;
      } */

      span {
        font-size: 22px;
        color: white;

        font-family: Poppins;
        font-style: normal;
      }
    }

    h2 {
      margin: auto;
    }

    h1 {
      margin: auto;
      font-family: Poppins-light;
      font-weight: 300;
      color: #ffffff;
    }
  }

  .header-img {
    position: relative;

    * {
      /* border: 1px solid red; */
    }
    .hero-img {
      object-fit: cover;
      width: 100%;
      position: absolute;
      height: 100%;
      z-index: 2;
      max-width: 1200px;
      /* display: none; */
    }
    .black {
      position: relative;
      height: 10rem;
      z-index: 1;
    }
    .white {
      position: relative;
      background: white;
      height: 10rem;

      z-index: 1;
    }
  }

  @media (min-width: 768px) {
    .titles-routes {
      flex-direction: row !important;
    }
  }
  @media (min-width: 992px) {
    .header-container {
      .titles {
        .titles-container {
          width: 80%;

          img {
            margin: 0;
            margin-bottom: 30px;
          }
        }
        h1 {
          font-size: 35px;
        }

        br {
          display: none;
        }
      }
    }

    .header-img {
      position: relative;

      * {
        /* border: 1px solid red; */
      }
      .hero-img {
        width: 80%;
      }
      .black {
        height: 20rem;
        z-index: 1;
      }
      .white {
        background: white;
        height: 20rem;
        /* position: relative; */
        /* z-index: 1; */
      }
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widht: undefined,
    };
  }

  componentDidMount() {
    this.setState({
      widht: window.innerWidth,
    });

    window.addEventListener("resize", (event) => this.getSize(event));
  }

  getSize(event) {
    this.setState({
      widht: event.target.innerWidth,
    });
  }

  renderImg() {
    if (this.props.img) {
      return this.props.img;
    } else {
      if (this.state.widht > 410) {
        return image1;
      } else {
        return responsiveimg;
      }
    }
  }

  render() {
    // console.log(this.props);
    return (
      <>
        <StyledHeader>
          <Container className="header-container">
            <div className="titles">
              <div className="titles-routes">
                {this.props.h4 ? (
                  this.props.link ? (
                    <Link to={this.props.link}>
                      <h4>/{this.props.h4}</h4>
                    </Link>
                  ) : (
                    <h4>/{this.props.h4}</h4>
                  )
                ) : null}

                {this.props.span ? <span>{this.props.span}</span> : null}
              </div>

              <div className="titles-container">
                {this.props.icon ? <img src={this.props.icon} alt="" /> : null}

                {this.props.h2 ? (
                  <h2 className="gradientText">{this.props.h2}</h2>
                ) : null}

                {this.props.h1 ? <h1>{this.props.h1}</h1> : null}
                {this.props.h3 ? <h3>{this.props.h3}</h3> : null}

                {this.props.date ? (
                  <div className="date">
                    <h3>{this.props.date}</h3>
                  </div>
                ) : null}

                {this.props.descripcion ? (
                  <div className="descripcion">
                    <h3>{this.props.descripcion}</h3>
                  </div>
                ) : null}
              </div>
            </div>
          </Container>

          <div className="header-img">
            <img
              className="hero-img"
              src={this.props.imagen || IMG}
              alt="header-img"
            />
            <div className="black">
              <Container style={{ position: "relative" }}>
                <ScrollIndicator bottom="150px" />
              </Container>
            </div>
            <div className="white">
              <Container style={{ position: "relative", minHeight: "auto" }}>
                <ScrollIndicator top isBlack />
              </Container>
            </div>
          </div>
        </StyledHeader>
      </>
    );
  }
}

export default withRouter(Header);
// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }
