import React, { Component } from "react";
import Container from "../components/Container";
import SDK from "../SDK";
import Header from "./sections/Header";
import styled from "styled-components";
import InnovemosSection from "./sections/InnovemosSection";
import Footer from "../pages/sections/Footer";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import BlogPrev from "./sections/BlogPrev";

// import IMAGE from "../assets/images/rappi-icon.svg";

const StyledEntrada = styled.div`
  .trends-container {
    display: flex;
    flex-direction: column;

    .blog {
      margin-bottom: 60px;
      img {
        margin: 20px -2.5%;
        width: 105%;
        max-width: 105%;
      }
    }
    h4 {
      letter-spacing: 0px;
    }
    a {
      display: inline-block;
      cursor: pointer;
      align-self: center;
      justify-self: center;
      text-decoration: none;
      // color: black;
      color: #3345da;
      margin-bottom: 5px;
      font-weight: bold;
      span {
        color: #a04d9a;
        margin-left: 2px;
      }

      ::after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        background: #3345da;
        transition: width 0.3s;
      }
      :hover::after {
        width: 100%;
        //transition: width .3s;
      }
    }
  }
`;

class Entrada extends Component {
  constructor() {
    super();
    this.state = {
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  }
  async componentDidMount() {
    if (!isNaN(this.props.entrada)) {
      try {
        var articulo_slug = await SDK.getArticuloSlugById(this.props.entrada);
        window.location = "/blog/" + articulo_slug;
      } catch (err) {
        console.log(err);
        window.location = "/";
      }
    } else {
      var articulo = await SDK.getArticulo(this.props.entrada);
      if (articulo.length === 0) {
        window.location = "/";
      }
      articulo = articulo[0];
      // console.log(articulo);
      var fecha = new Date(articulo.created_at);
      fecha = `${
        this.state.meses[fecha.getMonth()]
      } ${fecha.getDate()}, ${fecha.getFullYear()}`;
      this.setState({ articulo, fecha });
    }
  }

  render() {
    const CustomParagraph = ({ children }) => {
      return (
        <div
          style={{
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {children}
        </div>
      );
    };

    // console.log(this.state.articulo ? this.state.articulo.Imagen : "nada aún");
    return this.state.articulo ? (
      <StyledEntrada>
        <Helmet>
          <title>Bahia Trends - {this.state.articulo.Titulo}</title>

          <meta
            name="description"
            content={
              this.state.articulo.MetaDescripcion ||
              this.state.articulo.Descripcion
            }
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            property="og:url"
            content={`http://new.bahia360.mx/blog/${this.state.articulo.slug}`}
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={`Bahia Trends - ${this.state.articulo.Titulo}`}
          />
          <meta
            property="og:description"
            content={
              this.state.articulo.MetaDescripcion ||
              this.state.articulo.Descripcion
            }
          />
          <meta
            property="og:image"
            content={
              "https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/" +
              this.state.articulo.Imagen.url.split("/")[
                this.state.articulo.Imagen.url.split("/").length - 1
              ]
            }
          />
          <meta
            name="twitter:url"
            content={`http://new.bahia360.mx/blog/${this.state.articulo.slug}`}
          />
          <meta
            name="twitter:title"
            content={`Bahia Trends - ${this.state.articulo.Titulo}`}
          />
          <meta
            name="twitter:description"
            content={
              this.state.articulo.MetaDescripcion ||
              this.state.articulo.Descripcion
            }
          />
          <meta
            name="twitter:image"
            content={
              "https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/" +
              this.state.articulo.Imagen.url.split("/")[
                this.state.articulo.Imagen.url.split("/").length - 1
              ]
            }
          />
        </Helmet>

        <Header
          h4="BAHÍA TRENDS"
          descripcion={this.state.articulo.Descripcion}
          span={
            this.state.articulo.Titulo ? `/${this.state.articulo.Titulo}` : null
          }
          h1={this.state.articulo.Titulo ? this.state.articulo.Titulo : null}
          h3={this.state.articulo.Autor ? this.state.articulo.Autor : null}
          date={this.state.fecha ? this.state.fecha : null}
          imagen={
            this.state.articulo.Imagen.url
              ? this.state.articulo.Imagen.url
              : null
          }
          link="/blog"
        />
        <Container className="trends-container">
          <CustomParagraph>
            <ReactMarkdown
              className="blog"
              // renderers={{ text: (props) => <CustomParagraph {...props} /> }}
            >
              {this.state.articulo.Cuerpo}
            </ReactMarkdown>
          </CustomParagraph>
          <a href="/contactanos">
            ¡Contáctanos y cotiza con nosotros!
            <span>&rarr;</span>
          </a>
        </Container>
        <BlogPrev suggest={this.state.articulo} />
        <InnovemosSection />
        <Footer />
      </StyledEntrada>
    ) : (
      <></>
    );
  }
}

export default Entrada;
