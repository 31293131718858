import React, { Component } from "react";
import styled from "styled-components";
import ARCO from "../assets/PDF/ARCO_BAHIA360.pdf";
import AvisoPrivacidad from "../assets/PDF/AVISO_DE_PRIVACIDAD_EMPLEADOS.pdf";
const StyledAviso = styled.div`
  background: #e2e2ea;
  padding: 0 3rem;
  min-height: 100vh;
  color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  a {
    cursor: pointer;
    color: #3345da;
  }
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    color: black;
  }
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    .container_title{
      padding: 5rem;
    }
    .container_advice{
      padding: 3rem 7rem 3rem 0;
    }
    .title {
      font-size: 60px;
    }
  }
`;
export default class AvisoPrivacidadEmpleados extends Component {
  render() {
    return (
      <StyledAviso>
        <div className="container_title">
        <p className="title">
          AVISO DE PRIVACIDAD PARA COLABORADORES Y CANDIDATOS
        </p>
        </div>
        <div className="container_advice">
        <p>
          <strong>I. Identidad y domicilio del Responsable.</strong>
        </p>
        <p>
          Likeable Media México, SAPI. de C.V., conocido comercialmente como
          “Bahía 360”, con domicilio en Bahía de Santa Bárbara 145, Col.
          Verónica Anzures, CDMX, Alcaldía Miguel Hidalgo, CP 11300, es el
          responsable del tratamiento y protección de sus datos personales en
          términos del presente Aviso de privacidad.
        </p>
        <p>
          <strong>II. Datos personales que serán tratados.</strong>
        </p>
        <p>
          Para las finalidades especificadas en este aviso de privacidad, el
          responsable recaba directamente de candidatos durante el proceso de
          selección y reclutamiento de personal o indirectamente a través del
          sitio de Internet{" "}
          <a href="https://www.bahia360.mx/">https://www.bahia360.mx/</a>, o a
          través de otras fuentes permitidas por la ley, los siguientes datos:
        </p>
        <p>
          <strong>Datos necesarios para la Selección de Personal.</strong>
        </p>
        <p>
          Datos personales de identificación: Nombre (s) y apellido (s), copia
          de identificación oficial, fecha y lugar de nacimiento, copia del acta
          de nacimiento, fotografía del rostro, edad, nacionalidad, firma
          autógrafa, estado civil, sexo, Registro Federal de Contribuyentes
          (RFC), Clave Única de Registro de Población (CURP) y copia, número de
          seguridad social (NSS), copia de registro de afiliación al IMSS,
          domicilio y comprobante del mismo; licencia de conducir (cuando
          aplique).
          <p>
            Datos de contacto: domicilio, correo electrónico, números de
            teléfono de casa, recados y móvil.
          </p>
          <p>
            Datos de desarrollo profesional: Información académica, copia de la
            última constancia de estudios, información sobre trabajos anteriores
            y experiencia laboral, aspiraciones laborales, referencias laborales
            y cartas de recomendación.
          </p>
          <p>
            Datos del entorno social y familiar: Nombre completo de las personas
            con las que vive, nombre completo y datos de contacto de personas
            que pueden ser sus referencias personales, nombre completo y datos
            de contacto de dependientes económicos y familiares directos hasta
            tercer grado.
          </p>
          <p>
            Datos de seguridad y vigilancia: A toda persona que visita las
            instalaciones de la responsable, se recaba directamente su
            fotografía, detalles del equipo de cómputo que traigan consigo, así
            como las imágenes de los titulares que sean captadas y grabadas
            mediante cámaras de seguridad localizadas en puntos específicos
            dentro de los inmuebles.
          </p>
          <p>
            Dichos datos son tratados bajo estrictas medidas de seguridad que
            garanticen su confidencialidad.
          </p>
        </p>
        <p>
          <strong>
            III.Datos personales sensibles, financieros y patrimoniales que
            serán tratados.
          </strong>
        </p>
        <p>
          Como parte del proceso de reclutamiento y selección de personal, el
          responsable puede recabar y tratar datos personales de carácter
          sensible, financiero y patrimonial, mismos que se describen a
          continuación:
          <p>
            Datos sensibles: resultados de distintas pruebas de aptitudes y
            psicométricas, constancia de no antecedentes penales, afiliación
            sindical, así como resultados de pruebas y documentos que determinen
            sus capacidades técnicas, psicológicas y / o el estado de salud
            presente o futura(certificados médicos).
          </p>
          <p>
            Datos financieros y patrimoniales: Aspiraciones económicas, datos
            bancarios, información sobre sueldos presentes y pasados, estudios y
            perfil socioeconómicos.
          </p>
          <p>
            Dichos datos son tratados bajo estrictas medidas de seguridad que
            garanticen su confidencialidad
          </p>
        </p>
        <p>
          <strong>IV.Finalidades del tratamiento de datos personales.</strong>
        </p>
        <p>
          Primarias(Aquellas que dan origen y son necesarias para el proceso de
          reclutamiento y selección de personal)
          <p>
            Sus datos personales serán tratados con el objetivo de: i) incluirlo
            en un proceso de reclutamiento y selección de personal; ii) realizar
            estudios y perfil socioeconómicos(cuando aplique); iii) corroborar
            que la información y las referencias proporcionadas por usted sean
            veraces, comunicándonos con dichas referencias por cualquier medio
            de comunicación; iv) aplicación de pruebas(evaluaciones); v)
            realización de entrevistas; vi) tener contacto por cualquier medio
            de comunicación con los candidatos preseleccionados y seleccionados;
            vii) activación del proceso de contratación; y viii) permitir el
            acceso, control y videovigilancia / grabación para la seguridad de
            las personas que nos visitan así como de aquellas que laboran en los
            inmuebles.
          </p>
          <p>
            Secundarias(Aquellas que NO son necesarias para el proceso de
            reclutamiento y selección de personal)
          </p>
          <p>
            Sus datos personales podrán utilizarse para: i) compartirlos a
            terceros dedicados a la selección y reclutamiento de personal.
          </p>
        </p>
        <p>
          <strong>V.Transferencias de datos personales.</strong>
        </p>
        <p>
          De acuerdo con lo establecido en el presente aviso de privacidad, sus
          datos personales(salvo los datos sensibles, financieros y
          patrimoniales) podrán transferirse a terceros dedicados a la selección
          y reclutamiento de personal en otras empresas para que puedan tomar en
          cuenta su perfil para dichos fines.
        </p>
        <p>
          <strong>
            Medios y procedimiento para revocar el consentimiento y el ejercicio
            de Derechos ARCO.
          </strong>
        </p>
        Usted tiene derecho a acceder a sus datos personales que obre en poder
        del responsable y a los detalles del tratamiento de los mismos, así como
        a rectificarlos en caso de que su información personal este
        desactualizada, sea inexacta o incompleta; cancelarlos cuando considere
        que los mismos no están siendo tratados conforme a los principios,
        deberes y obligaciones previstos en la ley, o bien, oponerse al
        tratamiento de los mismos para fines específicos, siempre y cuando los
        mismos no sean necesarios para el cumplimiento de una obligación legal
        los(“Derechos ARCO”).
        <p>
          Asimismo, podrá revocar el consentimiento otorgado al responsable para
          el tratamiento de sus datos personales siempre y cuando los mismos no
          sean necesarios para la existencia, mantenimiento y cumplimiento de su
          relación jurídica con el responsable y / o cuestiones de seguridad.
        </p>
        <p>
          A.Requisitos de la Solicitud de revocación del consentimiento y de
          ejercicio de “Derechos ARCO”.
        </p>
        <p>
          En términos de lo señalado por la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares, toda Solicitud de
          revocación de consentimiento y ejercicio de “Derechos ARCO” debe
          contener y acompañar lo siguiente:
        </p>
        <ol>
          <li>
            Señalar el nombre del titular de los datos y su domicilio, u otro
            medio para comunicarle la respuesta a su solicitud.
          </li>
          <li>
            Acompañar los documentos que permitan acreditar su identidad, o en
            su caso, la representación del titular de los datos.
          </li>
          <li>
            Señalar una descripción clara y precisa de los datos personales
            respecto de los que busca ejercer su derecho de revocación de
            consentimiento o alguno de los “Derechos ARCO”. Si se trata de
            rectificación de datos personales deberáindicar las modificaciones a
            realizarse y aportar los documentos que sustenten su petición; y
          </li>
          <li>
            Señalar y / o acompañar cualquier otro elemento o documento que
            facilite la localización de los datos personales.
          </li>
        </ol>
        <p>
          Para ejercer su derecho de revocación de consentimiento, así como sus
          “Derechos ARCO”, usted podrá apoyarse en el Formato que se encuentra
          disponible en el sitio de Internet
          <a href={ARCO} target="_blank">
            https://www.bahia360.mxBahia_formatoARCO
          </a>
          (en la sección Formato de ayuda para solicitud ARCO).
        </p>
        <p>
          B.Medios para presentar las solicitudes de revocación de
          consentimiento y ejercicio de “Derechos ARCO”.
        </p>
        <p>
          Con el fin de que la presentación de las Solicitudes de revocación de
          consentimiento y ejercicio de “Derechos ARCO” sea sencilla, los
          titulares de los datos podrán presentar dichas solicitudes por las
          siguientes vías:
        </p>
        <p>
          1. 2. Por correo certificado con acuse de recibo al domicilio del
          responsable.
        </p>
        <p>
          Por correo electrónico a la dirección{" "}
          <a href="mailto: hola@bahia360.mx"> hola@bahia360.mx </a> siempre y cuando sea
          posible identificar fehacientemente al titular mediante mecanismos de
          autenticación permitidos por las disposiciones legales de la materia,
          y aceptados por el responsable.La utilización de firma electrónica
          avanzada o del instrumento electrónico que lo sustituya, eximirá de la
          presentación de la copia del documento de identificación.Se acusará
          recibo por correo electrónico indicando la fecha de la recepción de la
          Solicitud de revocación de consentimiento o ejercicio de “Derechos
          ARCO”.
        </p>
        <p>
          Plazo de atención de solicitudes de revocación de consentimiento y
          ejercicio de “Derechos ARCO”.
        </p>
        <p>
          C.La recepción de una Solicitud de revocación de consentimiento o
          ejercicio de “Derechos ARCO” no implica que se declare procedente por
          parte del responsable.En caso de que la información proporcionada en
          la Solicitud de ejercicio de “Derechos ARCO” sea insuficiente o
          errónea para atenderla, o bien no se acompañen los documentos
          necesarios para dar trámite a la misma, el responsable requerirá al
          titular de los datos o su representante, dentro de los 5 días hábiles
          siguientes a la recepción de la Solicitud de ejercicio de “Derechos
          ARCO”, por una única vez, para que aporte los elementos o documentos
          necesarios para dar trámite a la misma.El titular de los datos o su
          representante, contará con 10 días hábiles contados a partir del día
          siguiente de la recepción del requerimiento, para atender el mismo.
        </p>
        <p>
          El responsable dará respuesta a la Solicitud de revocación de
          consentimiento o ejercicio de “Derechos ARCO” con la determinación
          alcanzada, en un plazo de 20 días hábiles contados a partir de la
          recepción de la solicitud, o en caso de haber se solicitado
          información o documentos adicionales, en un plazo de 20 días hábiles
          contados a partir del día sigui ente a la presentación de la respuesta
          al requerimiento, para el caso de ejercicio de “Derechos ARCO”.
        </p>
        <p>
          En caso de ser procedente la Solicitud de revocación de consentimiento
          o ejercicio de “Derechos ARCO”, el responsable hará efectiva la
          determinación alcanzada dentro de un plazo de 15 días hábiles contados
          a partir de que se comunique la respuesta al titular de los datos o su
          representante.Tratándose de solicitudes de acceso a sus datos
          personales, la entrega de los mismos se hará previa acreditación de la
          identidad del titular de los datos o su representante.
        </p>
        <p>
          El responsable podrá ampliar los plazos para dar respuesta a una
          Solicitud de ejercicio de “Derechos ARCO”, y / o para hacer efectiva
          su determinación alcanzada, por una sola vez, por periodos iguales a
          los señalados en cada caso, siempre que el Departamento de Datos
          Personales considere que las circunstancias del caso lo
          justifican.Ante tales supuestos, el responsable le notificará al
          titular de los datos o su representante, la(s) circunstancia(s) que
          justifican la ampliación, dentro de cada uno de los plazos originales
          para dar respuesta o hacer efectiva la determinación alcanzada.
        </p>
        <p>
          Las respuestas a las solicitudes de revocación de consentimiento o
          ejercicio de “Derechos ARCO” se entregarán al titular de los datos o
          su representante, por correo electrónico o por correo certificado.La
          información o datos personales solicitados podrán ser puestos a
          disposición del titular de los datos o su representante, en copias
          simples o en archivo electrónico según el tipo y la cantidad de
          documentos de que se trate cada caso.
        </p>
        <p>
          El Departamento de Datos Personales será el encargado de dar trámite a
          las solicitudes de revocación de consentimiento y / o para el
          ejercicio de “Derechos ARCO”, así como atender cualquier duda que
          pudiera tener respecto al tratamiento de su información, contactando a
          dicho departamento a través los siguientes medios: i) Por correo
          certificado con acuse de recibo a l domicilio del responsable; y ii)
          por correo electrónico a la direcciónhola @bahia360.mx.
        </p>
        <p>
          <strong>
            VII.Opciones y medios para limitar el uso o divulgación de los datos
            personales.
          </strong>
        </p>
        <p>
          Con el objeto de que usted pueda limitar el uso y divulgación de su
          información, se ponen a disposición los siguientes medios:
        </p>
        <p>
          Su Inscripción en el Registro Público para Evitar Publicidad(REPEP),
          que está a cargo de la Procuraduría Federal del Consumidor(PROFECO),
          con la finalidad de no recibir publicidad vía telefónica de
          proveedores de sectores de: i) telecomunicaciones, ii) turismo y iii)
          comercial, ni que su información sea utilizada con fines
          mercadotécnicos o publicitarios.Para mayor información sobre este
          registro, usted puede consultar el portal de internet de la PROFECO, o
          bien ponerse en contacto directo con ésta.
        </p>
        <p>
          Enviar un correo electrónico a la direcciónhola @bahia360.mx,
          manifestando su negativa a seguir recibiendo publicidad por parte del
          responsable.
        </p>
        <p>
          <strong>Cookies.</strong>
        </p>
        <p>
          <strong>
            VIII.Le informamos que nuestro sitio de internet www.bahia360.mx
            utiliza cookies.Los datos personales que se obtienen a través de
            estas tecnologías son: i) la dirección IP, ii) versión del
            navegador, iii) sistema operativo y iv) identificador único.Mismo s
            que utilizamos para: i) identificar el país del visitante, ii)
            idioma del navegador, iii) tiempo promedio de estancia en el sitio,
            iv) número de visitas por día y mes, v) secciones que visita dentro
            del sitio, vi) en que banners da click y vii) generar reportes analí
            ticos y estadísticos.
          </strong>
        </p>
        <p>
          Estás tecnologías podrán deshabilitarse, dependiendo del navegador,
          siguiendo los siguientes pasos:
        </p>
        <ol>
          <ul>
            1. Buscar en la sección de ayuda del navegador la manera de
            desactivar cookies;
          </ul>
          <ul>2. Directo en la herramienta de configuración del navegador.</ul>
        </ol>
        <p>
          Para mayor información sobre el uso de estas tecnologías, puede
          consultar el sitio de Internet de su navegador.
        </p>
        <p>
          <strong>IX.Cambios o modificaciones al aviso de privacidad.</strong>
        </p>
        <p>
          El presente aviso puede sufrir modificaciones, cambios o
          actualizaciones en cualquier momento, derivado de nuevos
          requerimientos legales, en cuyo caso se hará de su conocimiento a
          través del sitio web:{" "}
          <a href="https://www.bahia360.mx">https://www.bahia360.mx</a> en la
          sección Aviso de Privacidad Integral de Candidatos.
        </p>
        <p>
          El procedimiento a través del cual se llevarán a cabo las
          notificaciones sobre modificaciones, cambios o actualizaciones al
          presente aviso de privacidad será a través de la publicación en el
          sitio web señalado anteriormente.
        </p>
        <p>
          <strong>X.Consentimiento</strong>
        </p>
        <p>
          Para dar consentimiento favor de descargar el <a href={AvisoPrivacidad} download>archivo adjunto</a>,
          firmarlo y enviarlo por correo electrónico a la dirección
          <a href="mailto: hola@bahia360.mx"> hola@bahia360.mx </a>
        </p>
        </div>
      </StyledAviso>
    );
  }
}
