import React from "react";
import styled from "styled-components";
import Container from "../../components/Container";

// imgs
import linkedin from "../../assets/images/linkedin.svg";
import facebook from "../../assets/images/facebook.svg";
import behance from "../../assets/images/behance.svg";
import instagram from "../../assets/images/instagram.svg";
import youtube from "../../assets/images/youtube.svg";

const StyledNav = styled.section`
  background: white;
  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  a {
    color: #232323;
    font-size: 18px;
    text-decoration: none;
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`;

const StyledHeader = styled.section`
  .black-line {
    width: 93px;
    height: 3px;
    background: black;
    margin: 30px 0;
  }

  .footer-body {
    .footer-nav {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 10px;
      }
      a:last-child {
        margin-bottom: 0px;
      }
    }

    .header {
      margin-bottom: 30px;

      .social-nav {
        display: flex;
        margin-bottom: 30px;
        a {
          margin-right: 15px;
        }
      }

      .contact-nav {
        display: flex;
        flex-direction: column;
        a {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .footer-body {
      display: flex;
      .footer-nav {
        flex: 1;
      }

      .header {
        flex: 1;
      }
    }
  }

  @media (min-width: 768px) {
    .footer-body {
      .footer-nav {
        max-width: 30%;
        a {
          font-size: 18px;
        }
      }

      .header {
        max-width: 30%;
        a {
          font-size: 18px;
        }
      }
    }
  }
`;

const StyledFooter = styled.section`
  background: #3345da;
  color: white;
  .footer-blue {
    padding-top: 20px;
    padding-bottom: 20px;

    p {
      margin: 0;
      font-size: 13px;
    }
    a{
      color: white;
      font-size: 13px;
    }
    .aviso{
      padding-bottom: 10px;
    }
  }
`;

function Footer() {
  return (
    <StyledNav>
      <StyledHeader>
        <Container className="container">
          <div className="black-line"></div>
          <div className="footer-body">
            {/* <div className="footer-nav">
              <a href=""> ¿Quienes somos?</a>
              <a href=""> Nuestros servicios</a>
              <a href=""> Talento</a>
              <a href=""> Contacto</a>
            </div> */}

            <div className="header">
              <div className="contact-nav">
                {/* <a href="tel:55124586"> Tel. 5512-4586</a> */}
                <a href="mailto:hola@bahia360.mx">hola@bahia360.mx</a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/VARIV+Capital/@19.435193,-99.1730821,15z/data=!4m5!3m4!1s0x0:0x5509711d33432523!8m2!3d19.435193!4d-99.1730821"
                >
                  Bahía de Santa Bárbara 145 Verónica Anzúres C.P. 11300 Ciudad
                  de México
                </a>
              </div>
              <div className="social-nav">
                <a
                  href="https://www.linkedin.com/company/bahiahub/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="LinkedIn" />
                </a>
                <a
                  href="https://www.facebook.com/BahiaInnovationHub/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
                <a
                  href="https://www.behance.net/bahiahub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={behance} alt="Behance" />
                </a>
                <a
                  href="https://www.instagram.com/bahiainnovationhub/?hl=es-la"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC8kjtro4b1BPRiVu836ubQQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} alt="Instagram" />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </StyledHeader>
      <StyledFooter>
        <Container className="footer-blue container">
        <p className="aviso">Consulta nuestro <a href="/aviso-privacidad"><strong>Aviso de privacidad</strong></a></p>
          <p>Todos los derechos reservados Bahía © 2020</p>
         
        </Container>
      </StyledFooter>
    </StyledNav>
  );
}

export default Footer;
