import React, { Component } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import Container from "../../components/Container";
import GooglePartner from "../../assets/images/googlePartner.png";

const CertificationContainer = styled.section`
  background: #F3F3F5;
  display: flex;

  .gradientCertification{
    display: block;
    margin: auto;
    font-family: Poppins;
    font-style: normal;
    font-size: 36px;
    background: linear-gradient(89.92deg, #9F4D9A 0.53%, #3345DA 99.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    /* identical to box height */
  }
  
  .data {
    margin-top: 40px;
    display: inline-block;
    font-family: Poppins;
    font-style: normal;
    font-size: 18px;
    color: #232323;
    margin-left: 30px;
    margin-right: 30px;
  }

  .certification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .imgGoogle {
    position: absolute;
    width: 100%;
    max-width: 130px;
    margin-top: 20%;
  }
  
  .boxContainer{
    background-color: white;
    width: 230px;
    height: 220px;
    margin: 10px auto;
    border: 0.347px solid #5F6368;
    display: block;
  }
  
  @keyframes mover{
    0%{
      transform: translateX(5%);
    }
    20%{
      transform: translateX(5%);
    }
    40%{
      transform: translateY(-5%);
    }
    60%{
      transform: translateX(-5%);
    }
    80%{
      transform: translateY(5%);
    }
    100%{
      transform: translateX(5%);
    }
  }
  
  .move:hover {
    animation: mover 700ms infinite;
    animation-play-state: initial;
  }

  .container {
    display: flex;
    flex-direction: row;
  }
  
  .header {
    margin-bottom: 40px;
  }
  @media (max-width: 900px) {
    .data {
      margin-top: 40px;
      font-size: 18px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .gradientCertification{
      font-size: 30px;
    }

    .certification {
      margin-top: 30px;
      text-align: center;
    }
    
    .container {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 390px) {
    display: flex;
    .header {
      margin-bottom: 50px;
    }
    .data {
      margin-top: 30px;
      font-size: 14px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .gradientCertification{
      font-size: 25px;
    }

    .certification {
      margin-top: 20px;
      text-align: center;
    }
    button {
      font-size: 13px;
    }
  }
`;

class Certification extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <CertificationContainer>
                <Container className="container">
                    <div className="data" >
                        <text>
                            La certificación de Ads es una acreditación profesional que Google otorga a quienes demuestran competencia en diferenres aspectos de campañas con Google Ads.<br/><br/> Se destaca por ofrecer formación e insights para mantenernos al día con las tendencias, acceso y asistencia técnica para lograr los objetivos de nuestros clientes, entre otras cosas.
                        </text>
                    </div>
                    <div className="certification">
                        <div className="move">
                            <span className="gradientCertification">CERTIFICACIÓN</span>
                            <div className="boxContainer"></div>
                        </div>
                        <img src={GooglePartner} alt={"Google"} className="imgGoogle"/>
                    </div>
                    <div className="data">
                        <text>
                            Esta certificación nos permite impulsar el crecimiento de nuestros clientes, adaptarnos a sus necesidades y ofrecerles soluciones personalizadas y asistencia en el proceso.
                        </text>
                        <br />
                        <br />
                        <br />
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                window.open('https://partnersdirectory.withgoogle.com/partners/2010903706');
                            }
                            }
                            dark
                            blackFont
                            button_text="Ver certificado"
                        />
                    </div>
                </Container>
            </CertificationContainer>
        );
    }
}

export default Certification;
