import React, { Component } from "react";
import InnovemosSection from "./sections/InnovemosSection";
import Footer from "./sections/Footer";
import NuestrosServicios from "./sections/NuestrosServiciosPlx";
import DecripccionService from "./sections/DecripcionService";
import Header from "./sections/Header";
import SDK from "../SDK";
import { Helmet } from "react-helmet";

class Servicio extends Component {
  constructor() {
    super();
    this.state = {
      servicio: null,
      tareas: [],
    };
  }

  async componentDidMount() {
    // console.log(this.props.servicio);
    var servicio = await SDK.getServicio(this.props.servicio);

    if (servicio.length === 0) {
      window.location = "/";
    }
    servicio = servicio[0];
    this.setState({ servicio });

    const tareas = await SDK.getTareasByService(servicio.id);
    this.setState({ tareas });
  }

  render() {
    return this.state.servicio ? (
      <div>
        <Helmet>
          <title>Bahia - Nuestros servicios/{this.state.servicio.Titulo}</title>
          {/* Hola */}

          <meta
            name="description"
            content={this.state.servicio.DescripcionIn}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            property="og:url"
            content={`http://new.bahia360.mx/blog/${this.state.servicio.slug}`}
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={`Bahia - Nuestros servicios/${this.state.servicio.Titulo}`}
          />
          <meta
            property="og:description"
            content={this.state.servicio.DescripcionIn}
          />
          <meta
            property="og:image"
            content={
              "https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/" +
              this.state.servicio.Imagen.url.split("/")[
                this.state.servicio.Imagen.url.split("/").length - 1
              ]
            }
          />
          <meta
            name="twitter:url"
            content={`http://new.bahia360.mx/blog/${this.state.servicio.slug}`}
          />
          <meta
            name="twitter:title"
            content={`Bahia - Nuestros servicios/${this.state.servicio.Titulo}`}
          />
          <meta
            name="twitter:description"
            content={this.state.servicio.DescripcionIn}
          />
          <meta
            name="twitter:image"
            content={
              "https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/" +
              this.state.servicio.Imagen.url.split("/")[
                this.state.servicio.Imagen.url.split("/").length - 1
              ]
            }
          />
        </Helmet>

        <Header
          h4="Nuestros servicios"
          span={`/${this.state.servicio.Titulo}`}
          h2={this.state.servicio.Titulo}
          h1={this.state.servicio.TituloIn}
          imagen={this.state.servicio.ImagenIn.url}
          link="/servicios"
        />
        <DecripccionService
          titulo={this.state.servicio.DescripcionIn}
          tareas={this.state.tareas}
        />
        <NuestrosServicios
          isBlack={true}
          servicioActual={this.state.servicio.id}
        />
        <InnovemosSection />
        <Footer />
      </div>
    ) : (
      <></>
    );
  }
}

export default Servicio;
