import React, { Component, createRef } from "react";
import styled from "styled-components";
import Link from "../../components/Link";
import Button from "../../components/Button";
import Plx from "react-plx";
import SDK from "../../SDK";

import Container from "../../components/Container";
import MobileView from "../sections/NuestrosServicios";
import Scroll from "../../components/ScrollIndicator";

import { Element, animateScroll as scroll } from "react-scroll";

const ServicesContainer = styled.section`
  position: relative;

  ${(props) => (props.isBlack ? "background: black;" : "background: white;")}

  .ourServices {
    position: relative;
    h4 {
    }
  }

  .selectedservice-container {
    position: sticky;
    top: 0;
    height: 0;
    translatex: 100%;
  }

  @media (max-width: 575.98px) {
    .ourServices {
      h4 {
        top: 80px;
        font-size: 18px;
      }
    }
  }

  display: block;
  @media (max-width: 991.98px) {
    display: none;
  }
`;

const StyledServiceSelectors = styled.section`
  display: flex;
  background: none;
  padding: 10px;

  align-items: center;
  justify-content: space-evenly;

  position: sticky;
  top: 140px;

  .service {
    ${(props) => (props.isBlack ? "color: #383838;" : "color: #e2e2ea;")}

    p {
      cursor: pointer !important;
    }
  }

  .service.active {
    ${(props) => (props.isBlack ? "color: white;" : "color: #232323;")}

    p {
      margin-bottom: 5px;
    }
    :after {
      content: "";
      display: block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: #9f4d9a;
      margin: auto;
    }
  }

  @media (max-width: 991.98px) {
    padding: 0px;
    justify-content: space-between;
    p {
      font-size: 13px;
    }
  }
`;

const SelectedService = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 1300px;
  margin: auto;
  height: calc(100vh - 120px);
  // padding-top: 150px;

  div {
    width: 45%;
  }
  img {
    width: 45%;
    transition: all 2s ease;
  }

  img:hover {
    transform: rotateY(-10deg);
  }
  button {
    margin-top: 65px;
  }

  h3,
  p {
    color: ${(props) => (props.isBlack ? "#FFFFFF" : "#232323")};
  }

  @media (max-width: 575.98px) {
    width: calc(100vw - 30px);
    img {
      display: none;
    }
    div {
      width: 80%;
    }
    padding-top: 260px;
    align-items: flex-start;

    .infoSection {
      h3,
      h2,
      p {
        margin: 10px 0;
      }
      a {
        button {
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 1550px) {
    img {
      width: 33%;
    }

    justify-content: flex-start;
    div {
      margin-right: 5rem;
    }
  }
`;

export default class NuestrosServiciosPlx extends Component {
  constructor(props) {
    super(props);
    this.title = createRef();
    this.medidas = {};
    this.state = {
      avaliableServices: false,
    };
  }

  async componentDidMount() {
    var services = await SDK.getServicios();

    services = services.sort((a, b) => (a.Indicador < b.Indicador ? -1 : 1));

    this.setState({
      positionTop: 1,
      avaliableServices: services,
    });

    window.addEventListener("scroll", this.handleScroll.bind(this), true);

    var sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf("Chrome") > -1) {
      this.setState({
        isSafari: false,
      });
    } else if (sUsrAg.indexOf("Safari") > -1) {
      this.setState({
        isSafari: true,
      });
    }

    const _servicios = document
      .getElementById("_servicios")
      .getBoundingClientRect();

    this.setState({
      height: _servicios.height,
      width: _servicios.width,
      coordinates: {
        x: _servicios.x,
        y: _servicios.y,
      },
    });
  }

  componentDidUpdate() {
    if (this.props.servicios && !this.state.avaliableServices) {
      this.setState({ avaliableServices: this.props.servicios });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    this.setState({
      current_pos: window.scrollY,
    });
  }

  isServiceActive(service, idx) {
    var ele = document.getElementById(`${service.Titulo.split(" ").join("-")}`);
    var isActive = false;
    var scroll = this.state.current_pos;

    if (ele !== null && this.state.coordinates) {
      const alturaDeServicio = ele.getBoundingClientRect().height;

      // const offsetTop =
      //   (parseInt(this.ParallaxData(service, idx)[0].startOffset) *
      //     window.innerHeight) /
      //   100;

      if (idx === 0) {
        if (scroll < this.state.coordinates.y + alturaDeServicio) {
          isActive = true;
        }
      } else if (idx === 1) {
        if (
          scroll > this.state.coordinates.y + alturaDeServicio &&
          scroll < this.state.coordinates.y + alturaDeServicio * 1.6
        ) {
          isActive = true;
        }
      } else if (idx === 2) {
        if (
          scroll > this.state.coordinates.y + alturaDeServicio * 1.6 &&
          scroll < this.state.coordinates.y + alturaDeServicio * 2.2
        ) {
          isActive = true;
        }
      } else if (idx === 3) {
        if (
          scroll > this.state.coordinates.y + alturaDeServicio * 2.2 &&
          scroll < this.state.coordinates.y + alturaDeServicio * 2.7
        ) {
          isActive = true;
        }
      } else if (idx === 4) {
        if (scroll > this.state.coordinates.y + alturaDeServicio * 2.7) {
          isActive = true;
        }
      }
    }
    return isActive;
  }

  ParallaxData(service, idx) {
    var offset = 120;
    var startValue = 0;
    var startValueOpacity = 1;
    if (idx > 0) {
      offset = 100 + idx * 50;
      startValue = 100;
      startValueOpacity = 2;
    }

    return [
      {
        start: ".ourServices",
        startOffset: offset + 10 + "vh",
        duration: "100vh",
        properties: [
          { startValue, endValue: -150, property: "translateX", unit: "vw" },
          {
            startValue: startValueOpacity,
            endValue: -0.5,
            property: "opacity",
          },
        ],
      },
    ];
  }

  goToELement(service, idx) {
    const offsetTop =
      (parseInt(this.ParallaxData(service, idx)[0].startOffset) *
        window.innerHeight) /
      100;

    const ele = document
      .getElementById(`${service.Titulo.split(" ").join("-")}`)
      .getBoundingClientRect();
    var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

    var div = ele.height / 2;
    var scroll;

    if (this.state.coordinates.y < ele.height) {
      scroll = isMac ? offsetTop - div - 60 : offsetTop - div - 30;
    } else {
      scroll = isMac
        ? offsetTop - div + this.state.coordinates.y - 155
        : offsetTop - div + this.state.coordinates.y - 127;
    }

    if (idx > 0) {
      return scroll;
    } else {
      return this.state.coordinates.y + 50;
    }
  }

  render() {
    // console.log(this.state.current_pos);
    return this.state.avaliableServices ? (
      <Element name="id_services" className="element" id="_servicios">
        <ServicesContainer isBlack={this.props.isBlack} ref={this.title}>
          <Container
            className="ourServices"
            id="myElement"
            style={{
              height:
                this.state.avaliableServices.length > 1
                  ? this.state.avaliableServices.length * 70 + "vh"
                  : "100vh",
            }}
          >
            <div
              style={{
                position: "sticky",
                top: 100,
                zIndex: this.state.avaliableServices.length + 1,
              }}
            >
              <h4>/Nuestros servicios</h4>
              <Scroll
                isBlack={this.props.isBlack ? false : true}
                top
                topDistance={0}
                rightDistance="-50px"
              />
              <StyledServiceSelectors
                isBlack={this.props.isBlack}
                style={{ zIndex: this.state.avaliableServices.length + 1 }}
              >
                {this.state.avaliableServices.map((service, idx) => {
                  return (
                    <div
                      className={`service ${
                        this.isServiceActive(service, idx) ? "active" : ""
                      }`}
                      key={idx}
                    >
                      <p
                        onClick={() =>
                          scroll.scrollTo(this.goToELement(service, idx), {
                            duration: this.state.isSafari === true ? 700 : 100,
                          })
                        }
                      >
                        {service.Titulo}
                      </p>
                    </div>
                  );
                })}
              </StyledServiceSelectors>
            </div>

            {this.state.avaliableServices.map((service, idx) => {
              return (
                <Plx
                  parallaxData={this.ParallaxData(service, idx)}
                  style={{
                    position: "sticky",
                    top: 150,
                    height: 0,
                    translateX: "100%",
                    zIndex: this.state.avaliableServices.length - idx,
                  }}
                  className={idx > 0 ? "no_view" : ""}
                  key={idx}
                >
                  <SelectedService
                    name={`${service.Titulo.split(" ").join("-")}`}
                    className={`${service.Titulo.split(" ").join("-")}`}
                    isBlack={this.props.isBlack}
                    id={`${service.Titulo.split(" ").join("-")}`}
                  >
                    <div className="infoSection">
                      <h3>
                        <b>/{service.Indicador}</b>
                      </h3>
                      <h2 className="gradientText">{service.Titulo}</h2>
                      <p>{service.Descripcion}</p>
                      {this.props.servicioActual !== service.id ? (
                        <Link to={`/servicio/${service.slug}`}>
                          <Button
                            button_text="CONOCE MÁS"
                            dark
                            whiteFont={this.props.isBlack}
                          ></Button>
                        </Link>
                      ) : null}
                    </div>
                    <img src={service.Imagen.url} alt={service.Titulo} />
                  </SelectedService>
                </Plx>
              );
            })}
          </Container>
        </ServicesContainer>
        <MobileView
          services={this.state.avaliableServices}
          servicioActual={this.props.servicioActual}
          isBlack={this.props.isBlack}
        />
      </Element>
    ) : (
      <h1>Cargando</h1>
    );
  }
}
