import React from "react";
import styled from "styled-components";

const StyledScrollIndicator = styled.div`
  position: absolute;
  display: none;
  z-index: 1;
  img {
    width: 30px;
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.top
      ? `top: ${props.topDistance || "250px"}`
      : `bottom: ${props.bottom || "150px"}`};

  ${(props) => `right: ${props.rightDistance || "0px"};`}
`;

export default function ScrollIndicator({
  isBlack,
  top,
  rightDistance,
  topDistance,
  bottom,
}) {
  return (
    <StyledScrollIndicator
      className="scroll"
      top={top}
      rightDistance={rightDistance}
      topDistance={topDistance}
      bottom={bottom}
    >
      <img
        src={
          isBlack
            ? require("../assets/images/scroll_negro.png").default
            : require("../assets/images/scroll_blanco.png").default
        }
        alt="scroll"
      />
    </StyledScrollIndicator>
  );
}
