import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import Badge from "../assets/images/badge.png";

const StyledProject = styled.div`
  padding: 50px 50px;
  width: 40%;
  img {
    width: 100%;
  }
  
  .badge{
    position: absolute;
    width: 68px;
    height: 96px;
    top: -20px;
    left: 80%;
  }

  .imagen {
    transition: all 2s ease;
  }

  &:hover .imagen.par {
    transform: rotateY(-5deg);
  }

  &:hover .imagen.non {
    transform: rotateY(5deg);
  }

  button {
    margin: 50px 0;
  }

  perspective: 900px;

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 1200px) {
    width: 35%;
    .badge{
      position: absolute;
      width: 48px;
      height: 66px;
      top: -20px;
      left: 80%;
    }
  }

  @media (max-width: 767.98px) {
    padding: 50px 0;
    width: 40%;
    img {
      width: 100%;
    }
    h3 {
      padding-bottom: 20px;
    }
    button {
      padding: 20px 0;
    }
    .badge{
      position: absolute;
      width: 48px;
      height: 66px;
      top: -15px;
      left: 75%;
    }
  }

  @media (max-width: 575.98px) {
    width: 100%;
    .badge{
      position: absolute;
      width: 68px;
      height: 96px;
      top: -25px;
      left: 80%;
    }
  }
`;

export default function ProjectPreview({ project, idx, path }) {
  return (
      <>
          {
              <StyledProject className="ProjectPreview">
                      {project?.Titulo === "Infonavit Digital" ?
                              <div className={idx % 2 === 0 ? "imagen par" : "imagen non"} style={{position: "relative"}}>
                                  <img src={Badge} alt={"Badge"} className="badge"/>
                                  <img src={project.Imagen.url} alt={project.Titulo}/>
                              </div>
                          :
                          <div className={idx % 2 === 0 ? "imagen par" : "imagen non"}>
                            <img src={project.Imagen.url} alt={project.Titulo}/>
                          </div>
                      }
                  <h3>{project.Titulo}</h3>
                  <p>{project.Descripcion}</p>
                  <Button
                      onClick={() =>
                          (window.location = `/${path ? path : "proyecto"}/${project.slug}`)
                      }
                      dark
                      whiteFont
                      button_text="CONOCE MÁS"
                  />
              </StyledProject>
          }
      </>
  );
}
