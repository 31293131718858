import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Container from "./Container";
import { Link, NavLink, useLocation } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";

import bahia_logo_blanco from "../assets/images/bahia_logo_blanco_fueled.svg";
import bahia_logo_negro from "../assets/images/bahia_logo_blanco_fueled_black.svg";
import fb from "../assets/images/facebook-blanco.svg";
import linkedin from "../assets/images/linkedin-blanco.svg";
import behance from "../assets/images/behance-blanco.svg";
import instagram from "../assets/images/instagram-blanco.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";

const StyleNav = styled.nav`
  background: transparent;
  position: fixed;
  width: 100%;
  z-index: 1000;

  ${(props) => {
    return props.Contact
      ? props.secondary
        ? "animation: navToWhite 0.5s forwards ease-in;"
        : "animation: navToTransparent2 0.5s forwards ease-in;"
      : props.secondary
      ? "animation: navToBlack 0.5s forwards ease-in;"
      : "animation: navToTransparent 0.5s forwards ease-in;";
  }}

  @keyframes navToWhite {
    from {
      background: transparent;
    }
    to {
      background: white;
    }
  }

  @keyframes navToTransparent2 {
    from {
      background: white;
    }
    to {
      background: transparent;
    }
  }

  @keyframes navToBlack {
    from {
      background: transparent;
    }
    to {
      background: black;
    }
  }

  @keyframes navToTransparent {
    from {
      background: black;
    }
    to {
      background: transparent;
    }
  }

  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation-delay: 3s;

    ${(props) => {
      return props.secondary
        ? "animation: paadingTo10 0.5s forwards ease-in;"
        : "animation: originalPadding 0.5s forwards ease-in;";
    }};

    @keyframes paadingTo10 {
      100% {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    @keyframes originalPadding {
      from {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      to {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
      }
    }

    .logo {
      display: flex;
      img {
        object-fit: contain;
        max-width: 100%;
        margin: auto;
      }
    }

    .MenuBtn {
      :hover {
        transform: scale(1.2);
      }
      cursor: pointer;
      position: relative;
      width: 38px;
      height: 8px;
      .menu-global {
        backface-visibility: hidden;
        position: absolute;
        background-color: ${(props) => {
          return props.Contact ? "black" : "white";
        }};
        left: 0;
        width: 100%;
        height: 3px;
        transition: 0.55s;
      }
      .menu-top {
        top: 0;
      }
      .menu-bottom {
        top: 8px;
      }
    }
  }
`;

const MenuContent = styled.div`
  ${(props) => {
    return props.nav ? "" : "display:none";
  }};

  ${(props) => {
    return props.Delay ? "animation: fadeOut 0.6s forwards ease-in;" : "";
  }};

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  z-index: 10000;
  background-color: #000000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;

  height: 100%;

  .nav-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .closeMenu {
      :hover {
        transform: scale(1.2);
      }
      width: 38px;
      height: 38px;
      position: absolute;

      cursor: pointer;
      align-self: flex-end;
      .menu-global {
        position: absolute;

        background-color: white;

        height: 3px;
        transition: 0.55s;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      .menu-top {
        transform: rotate(45deg);
      }
      .menu-bottom {
        transform: rotate(-45deg);
      }
    }

    .menu-body {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      margin-bottom: 20px;
      
      .menuContent_links {
        list-style: none;
        padding: 0;
        a{
        text-decoration: none;
      }
        li {
          margin-bottom: 15px;
          .nav-item {
            font-family: Poppins-light;
            font-style: normal;
            cursor: pointer;
            font-size: 30px;
            margin: 0;
            color: #ffffff;
            text-decoration: none;
            ::after {
              content: "";
              display: block;
              width: 0;
              height: 3px;
              background: #fff;
              transition: width 0.3s;
            }
            :hover::after {
              width: 100%;
              //transition: width .3s;
            }
          }
        }
      }

      .line {
        display: none;
      }

      .mainSpace {
        .socialLogos {
          display: flex;

          a {
            transition: all 0.2s ease;
            :hover {
              transform: scale(1.2);
            }
            img {
              margin-right: 20px;
              height: 25px;
            }

            svg {
              .body {
                fill: white;
              }

              .center {
                fill: black;
              }
            }
          }
        }

        .footerDataContainer {
          margin-top: 4rem;
          display: flex;
          flex-direction: column;
          .nav-footer-item {
            font-family: Poppins-light;
            font-style: normal;

            font-size: 30px;

            color: #ffffff;
            text-decoration: none;
            margin-bottom: 2rem;
            ::after {
              content: "";
              display: block;
              width: 0;
              height: 3px;
              background: #fff;
              transition: width 0.3s;
            }
            :hover::after {
              width: 100%;
              //transition: width .3s;
            }
          }
        }
      }
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    .nav-item {
      font-size: 40px !important;
    }

    .menu-body {
      display: flex;

      margin-bottom: 20px;

      align-items: center;
      justify-content: center;
      .container {
        flex: 1;

        display: flex;
      }
      .menuContent_links {
        flex: 1 0 70%;
        margin: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .line {
        display: flex !important;

        border: 1px solid rgba(139, 139, 147, 0.5);
        position: absolute;
        height: 111vh;
        top: 0;
      }

      .mainSpace {
        display: flex;
        flex-direction: column-reverse;

        flex: 1 0 30%;

        align-items: flex-start;
        justify-content: flex-end;

        .socialLogos,
        .footerDataContainer {
          margin-left: 50px !important;
        }

        .footerDataContainer {
          margin-top: 0rem !important;
        }
      }
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
    .nav-item {
      font-size: 50px !important;
    }
  }
`;

function Navbar(props) {
  const [state, setstate] = useState(false);
  const [Delay, setDelay] = useState(false);
  const [Scroll, setScroll] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [Time, setTime] = useState(false);
  const [Contact, setContact] = useState(false);

  let { pathname } = useLocation();

  const changeOpen = () => {
    setstate(!state);
    setDelay(false);
  };

  const changeOpenDelay = () => {
    setDelay(true);
    setTimeout((e) => {
      setstate(!state);
    }, 500);
  };

  useEffect(() => {
    var sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf("Chrome") > -1) {
      setTime(500);
    } else if (sUsrAg.indexOf("Safari") > -1) {
      setTime(1000);
    }

    // this changes the scrolling behavior to "smooth"
  }, []);

  useEffect(() => {
    if (state) {
      document.body.style.overflow = "hidden";
      document.body.scroll = "no";
    } else {
      document.body.style.overflow = "auto";
      document.body.scroll = "yes";
    }
  }, [state]);

  document.addEventListener("scroll", (e) => {
    window.scrollY > 50 ? setScroll(true) : setScroll(false);
  });

  useEffect(() => {
    if (pathname.length === 1 && pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }

    if (pathname === "/contactanos") {
      setContact(true);
    } else {
      setContact(false);
    }
  }, [pathname]);

  // useEffect(() => {
  //   console.log(Scroll);
  // }, [Scroll]);

  const goToContact = () => {
    setstate(!state);
    setDelay(false);

    scroll.scrollToBottom({ duration: Time });
  };

  const scrollTo = (elem) => {
    changeOpen();
    scroller.scrollTo(elem, {
      duration: Time,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: elem === "_trends" ? -50 : 0,
    });
  };

  const scrollToProyects = (elem) => {
    changeOpen();
    scroller.scrollTo(elem, {
      duration: Time,
      delay: 0,
      offset: elem === "/proyectos" ? -50 : 0,
    });
  };

  const scrollToP = () => {
    setstate(false);
    window.scrollTo(0, 0);
  }

  const openMenu = () => {
    return (
      <MenuContent nav={state} Delay={Delay} className="fade-in-fast">
        <Container className="nav-container ">
          <div className="closeMenu" onClick={() => changeOpenDelay()}>
            <span className="menu-global menu-top"></span>
            <span className="menu-global menu-bottom"></span>
          </div>

          <div className="menu-body">
            <div className="container">
              {isHome ? (
                <ul className="menuContent_links">
                  <li>
                    <Link
                      className="nav-item"
                      to="/about"
                      onClick={() => changeOpen()}
                    >
                      ¿QUIÉNES SOMOS?
                    </Link>
                  </li>

                  <li>
                    <p
                      className="nav-item"
                      onClick={() => scrollTo("_servicios")}
                    >
                      NUESTROS SERVICIOS
                    </p>
                  </li>
                <NavLink
                to={"/proyectos"}
                onClick={() => scrollToP()}
                >
                  <li>
                    <p
                      className="nav-item"
                      // onClick={() => scrollToProyects()}
                    >
                      NUESTROS PROYECTOS
                    </p>
                  </li>
                  </NavLink>
                  <li>
                    <p className="nav-item" onClick={() => scrollTo("_trends")}>
                      BAHÍA TRENDS
                    </p>
                  </li>
                  {/* <li>
                    <a href="">TALENTO</a>
                  </li> */}
                  <li>
                    <p className="nav-item" onClick={() => goToContact()}>
                      CONTACTO
                    </p>
                  </li>
                </ul>
              ) : (
                <ul className="menuContent_links">
                  <li>
                    <Link
                      className="nav-item"
                      to="/"
                      onClick={() => changeOpen()}
                    >
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-item"
                      to="/about"
                      onClick={() => changeOpen()}
                    >
                      ¿QUIÉNES SOMOS?
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="nav-item"
                      to="/servicios"
                      onClick={() => changeOpen()}
                    >
                      NUESTROS SERVICIOS
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-item"
                      to="/proyectos"
                      onClick={() => scrollToP()}
                      // onClick={() => changeOpen()}
                    >
                      NUESTROS PROYECTOS
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-item"
                      to="/blog"
                      onClick={() => changeOpen()}
                    >
                      BAHÍA TRENDS
                    </Link>
                  </li>

                  {/* <li>
                    <a href="">TALENTO</a>
                  </li> */}
                  <li>
                    <Link
                      className="nav-item"
                      to="/contactanos"
                      onClick={() => changeOpen()}
                    >
                      CONTACTO
                    </Link>
                  </li>
                </ul>
              )}

              <div className="mainSpace">
                <div className="line"></div>
                <div className="socialLogos">
                  <a
                    href="https://www.linkedin.com/company/bahiahub/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                  <a
                    href="https://www.facebook.com/BahiaInnovationHub/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fb} alt="fb" />
                  </a>
                  <a
                    href="https://www.behance.net/bahiahub"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={behance} alt="behance" />
                  </a>
                  <a
                    href="https://www.instagram.com/bahiainnovationhub/?hl=es-la"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={instagram} alt="Instagram" />
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UC8kjtro4b1BPRiVu836ubQQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Youtube />
                  </a>
                </div>

                <div className="footerDataContainer">
                  <a className="nav-footer-item" href="mailto:hola@bahia360.mx">
                    hola@bahia360.mx
                  </a>

                  <a
                    className="nav-footer-item"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.google.com/maps/place/VARIV+Capital/@19.435193,-99.1730821,15z/data=!4m5!3m4!1s0x0:0x5509711d33432523!8m2!3d19.435193!4d-99.1730821"
                  >
                    Bahía de Santa Bárbara 145 Verónica Anzúres C.P. 11300
                    Ciudad de México
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MenuContent>
    );
  };

  return (
    <>
      <StyleNav secondary={Scroll} Contact={Contact} id="nav">
        <Container className={`navbar-container`}>
          <div className="logo">
            <img
              src={Contact ? bahia_logo_negro : bahia_logo_blanco}
              alt="bahia_logo_blanco"
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = "/")}
            />
          </div>

          <div className="MenuBtn" onClick={() => changeOpen()}>
            <span className="menu-global menu-top"></span>
            <span className="menu-global menu-bottom"></span>
          </div>
        </Container>
      </StyleNav>

      {openMenu()}
    </>
  );
}

export default Navbar;
