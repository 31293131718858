import React, { Component } from "react";

import InnovemosSection from "./sections/InnovemosSection";
import Footer from "./sections/Footer";
import Proyectos from "./sections/NuestrosProyectos";
import styled from "styled-components";

import { Helmet } from "react-helmet";

const StyledProyectos = styled.div`
  padding-top: 115px;
  background: black;
`;

export default class Servicios extends Component {
  render() {
    return (
      <StyledProyectos>
        <Helmet>
          <meta
            name="description"
            content="Cambiamos las reglas del marketing e impulsamos la digitalización de clientes como Bayer, Caja Popular Mexicana, Infonavit y Pepsi."
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:url" content={`http://new.bahia360.mx`} />
          <meta name="twitter:url" content={`http://new.bahia360.mx`} />
          <meta name="twitter:title" content={`Bahia - Fueled by People`} />
          <meta
            name="keywords"
            content="innovación tecnológica, comunicación efectiva, creatividad e innovación"
          />
        </Helmet>
        <Proyectos />
        <InnovemosSection />
        <Footer />
      </StyledProyectos>
    );
  }
}
