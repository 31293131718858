import React, { Component } from "react";
import InnovemosSection from "./sections/InnovemosSection";
import Footer from "./sections/Footer";
import styled from "styled-components";
import Entradas from "./sections/Entradas";

const StyledProyectos = styled.div`
  padding-top: 115px;
  background: black;
`;

export default class Servicios extends Component {
  render() {
    return (
      <StyledProyectos>
        <Entradas />
        <InnovemosSection />
        <Footer />
      </StyledProyectos>
    );
  }
}
